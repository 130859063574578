import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ownPerformanceAppraisalList: {},
  loading: false,
  error: "",
  singleOwnPerformanceAppraisal: {},
  adding: false,
};

const ownPerformanceAppraisalSlice = createSlice({
  name: "ownPerformanceAppraisal",
  initialState: initialState,
  reducers: {
    getOwnPerformanceAppraisalList: (state, action) => {
      state.loading = true;
    },
    getOwnPerformanceAppraisalListSuccess: (state, action) => {
      state.ownPerformanceAppraisalList = action.payload;
      state.loading = false;
    },
    getOwnPerformanceAppraisalListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addOwnPerformanceAppraisalData: (state, action) => {},
    getSingleOwnPerformanceAppraisal: (state, action) => {
      state.loading = true;
    },
    getSingleOwnPerformanceAppraisalSuccess: (state, action) => {
      state.singleOwnPerformanceAppraisal = action.payload;
      state.loading = false;
    },
    getSingleOwnPerformanceAppraisalFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteOwnPerformanceAppraisal: (state, action) => {
      state.adding = true;
    },
    deleteOwnPerformanceAppraisalSuccess: (state, action) => {
      state.adding = false;
    },
    deleteOwnPerformanceAppraisalFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getOwnPerformanceAppraisalList,
  getOwnPerformanceAppraisalListSuccess,
  getOwnPerformanceAppraisalListFailure,
  addOwnPerformanceAppraisalData,
  getSingleOwnPerformanceAppraisal,
  getSingleOwnPerformanceAppraisalSuccess,
  getSingleOwnPerformanceAppraisalFailure,
  deleteOwnPerformanceAppraisal,
  deleteOwnPerformanceAppraisalSuccess,
  deleteOwnPerformanceAppraisalFailure,
} = ownPerformanceAppraisalSlice.actions;

export default ownPerformanceAppraisalSlice.reducer;
