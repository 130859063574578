import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openKpiSetupList: {},
  loading: false,
  error: "",
  singleOpenKpiSetup: {},
  adding: false,
  singleLoading: false,
  creatingLoading: false,
  openAppraisalList: [],
};

const openKpiSetupSlice = createSlice({
  name: "openKpiSetup",
  initialState: initialState,
  reducers: {
    resetOpenKpiSetup: (state, action) => {
      state.storeDepartmentList = [];
      state.singleOpenKpiSetup = {};
    },
    storeAppraisal: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getOpenKpiSetupList: (state, action) => {
      state.loading = true;
    },
    getOpenKpiSetupListSuccess: (state, action) => {
      state.openKpiSetupList = action.payload;
      state.loading = false;
    },
    getOpenKpiSetupListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addOpenKpiSetupData: (state, action) => {
      state.creatingLoading = true;
    },
    addOpenKpiSetupDataSuccess: (state, action) => {
      state.creatingLoading = false;
    },
    addOpenKpiSetupDataFail: (state, action) => {
      state.creatingLoading = false;
    },
    addOpenAppraisalData: (state, action) => {
      state.creatingLoading = true;
    },
    addOpenAppraisalDataSuccess: (state, action) => {
      state.creatingLoading = false;
    },
    addOpenAppraisalDataFail: (state, action) => {
      state.creatingLoading = false;
    },
    getSingleOpenKpiSetup: (state, action) => {
      state.singleLoading = true;
    },
    getSingleOpenKpiSetupSuccess: (state, action) => {
      state.singleOpenKpiSetup = action.payload;
      state.singleLoading = false;
    },
    getSingleOpenKpiSetupFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },
    deleteOpenKpiSetup: (state, action) => {
      state.adding = true;
    },
    deleteOpenKpiSetupSuccess: (state, action) => {
      state.adding = false;
    },
    deleteOpenKpiSetupFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    reOpenKpiSetupData: (state, action) => {
      state.creatingLoading = true;
    },
    reOpenKpiSetupDataSuccess: (state, action) => {
      state.creatingLoading = false;
    },
    reOpenKpiSetupDataFail: (state, action) => {
      state.creatingLoading = false;
    },
  },
});

export const {
  resetOpenKpiSetup,
  storeAppraisal,
  getOpenKpiSetupList,
  getOpenKpiSetupListSuccess,
  getOpenKpiSetupListFailure,
  addOpenKpiSetupData,
  addOpenKpiSetupDataSuccess,
  addOpenKpiSetupDataFail,
  getSingleOpenKpiSetup,
  getSingleOpenKpiSetupSuccess,
  getSingleOpenKpiSetupFailure,
  deleteOpenKpiSetup,
  deleteOpenKpiSetupSuccess,
  deleteOpenKpiSetupFailure,
  addOpenAppraisalData,
  addOpenAppraisalDataSuccess,
  addOpenAppraisalDataFail,
  reOpenKpiSetupData,
  reOpenKpiSetupDataSuccess,
  reOpenKpiSetupDataFail,
} = openKpiSetupSlice.actions;

export default openKpiSetupSlice.reducer;
