import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: "",
  singleUser: {},
  vacationData: {},
  vacationLoading: false,
  currentTab: "Info",
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialState,
  reducers: {
    storeEmployeeProfile: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    resetEmployee: (state, action) => {
      state.loading = false;
      state.error = "";
      state.singleUser = {};
      state.currentTab = "Info";
    },
    getSingleUser: (state, action) => {
      state.loading = true;
    },
    getSingleUserSuccess: (state, action) => {
      state.singleUser = action.payload;
      state.loading = false;
    },
    getSingleUserFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    employeeVacation: (state, action) => {
      state.vacationLoading = true;
    },
    employeeVacationSuccess: (state, { payload }) => {
      // const {leaveSummary, leaveTypes} = payload;
      // let data = [];
      // leaveTypes?.forEach(el => {
      //   let obj = {};
      //   obj.fullName = el?.fullName;
      //   obj.colorCode = el?.colorCode;
      //   obj.numberLeaveDays = el?.numberLeaveDays;
      // })
      state.vacationData = payload;
      state.vacationLoading = false;
    },
    employeeVacationFailure: (state, action) => {
      state.error = action.payload.message;
      state.vacationLoading = false;
    },
    employeeForgotPassword: (state, action) => {},
    employeeResetPassword: (state, action) => {},
  },
});

export const {
  getSingleUser,
  getSingleUserSuccess,
  getSingleUserFailure,
  employeeVacation,
  employeeVacationSuccess,
  employeeVacationFailure,
  storeEmployeeProfile,
  resetEmployee,
  employeePasswordUpdate,
  employeeForgotPassword,
  employeeResetPassword,
} = userProfileSlice.actions;

export default userProfileSlice.reducer;
