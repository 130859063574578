import moment from "moment";

const formatTime = (value) => {
  const [hour, minute] = value?.split(":");
  let period = "AM";
  if (hour > 12) {
    period = "PM";
  }
  return `${hour}:${minute} ${period}`;
};

const reverseTime = (value) => {
  const [timeHour, period] = value?.split(" ");
  let [hour, minute] = timeHour?.split(":");
  if (period === "PM") {
    hour = (hour * 1 + 12)?.toString();
  }
  return `${hour}:${minute}`;
};

const convert12To24 = (data) => {
  const [time, modifier] = data?.split(" ");
  let [hours, minutes] = time?.split(":");
  if (hours === "12") {
    hours = "00";
  }
  if (modifier === "PM") {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

const convert24To12 = (data) => {
  let time = data;
  let hourEnd = time?.indexOf(":");
  let H = +time?.substr(0, hourEnd);
  let h = H % 12 || 12;
  let nH = h > 9 ? h : `0${h}`;
  let ampm = H < 12 ? "AM" : "PM";
  time = nH + time?.substr(hourEnd, 3) + " " + ampm;
  return time;
};

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

/// class

//. convert utc to local date
function convertUTCDateToLocalDate(date) {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
}
// function convertUTCDateToLocalDate(date) {
//   const newDate = new Date(
//     date.getTime() + date.getTimezoneOffset() * 60 * 1000
//   )
//   // console.log(`newDate`, newDate)
//   const offset = date.getTimezoneOffset() / 60
//   console.log(`offset`, offset, newDate.getHours(), newDate.getMinutes())
//   const hours = date.getHours()
//   newDate.setHours(hours - offset)
//   console.log(`hours`, hours, newDate.getHours(), newDate.getMinutes())
//   return newDate
// }

// . get local time
const timeDate = () => {
  const date = new Date();
  const dayMonthYear = moment(date).format("YYYY-MM-DD");
  const min = date.getMinutes();
  const d = `0${min}`;
  const time = `${date.getHours()}:${min > 9 ? date.getMinutes() : d}`;
  return `${dayMonthYear}T${time}`;
};

// .get UTC time
const getUTCTime = (data) => {
  const hour = new Date(
    moment(data.split("T").join(" ")).format("MMMM D, YYYY H:mm:ss")
  ).getUTCHours();
  const min = new Date(
    moment(data.split("T").join(" ")).format("MMMM D, YYYY H:mm:ss")
  ).getUTCMinutes();
  const d = `0${min}`;
  return convert24To12(`${hour}:${min > 9 ? min : d}`);
};

//. utc to local with day
const UTCtoLocal = (date, time) => {
  const timeDate = `${date} ${convert12To24(time)}`;
  const data = moment(timeDate).format("MMMM D, YYYY H:mm:ss");
  const localTime = convertUTCDateToLocalDate(new Date(data));
  const format = moment(localTime)
    .format("YYYY-MM-DD H:mm")
    .split(" ")
    .join("T");
  return format;
};

const UTCtoLocalPlus = (date, time) => {
  const timeDate = `${date} ${convert12To24(time)}`;
  const data = moment(timeDate).format("MMMM D, YYYY H:mm:ss");
  const localTime = convertUTCDateToLocalDate(new Date(data));
  const format = moment(localTime)
    .add(1, "hour")
    .format("YYYY-MM-DD H:mm")
    .split(" ")
    .join("T");
  return format;
};

// . utc to local time
const UTCtoLocalTime = (date, time) => {
  const timeDate = `${date} ${convert12To24(time)}`;
  const data = moment(timeDate).format("MMMM D, YYYY H:mm:ss");
  // console.log(`line 91 ~ UTCtoLocal ~ data`, new Date(data).toString())
  const localTime = convertUTCDateToLocalDate(new Date(data));
  // console.log(`line 93 ~ UTCtoLocal ~ localTime`, localTime)
  const format = moment(localTime).format("h:mm a");
  // console.log(`UTCtoLocal ~ format`, format)
  return format;
};

const time = {
  10: { start: "10:00:00", end: `10:30:00` },
  1030: { start: "10:30:00", end: `11:00:00` },
  11: { start: "11:00:00", end: `11:30:00` },
  1130: { start: "11:30:00", end: `12:00:00` },
  12: { start: "12:00:00", end: `12:30:00` },
  1230: { start: "12:30:00", end: `13:00:00` },
  1: { start: "13:00:00", end: `13:30:00` },
  130: { start: "13:30:00", end: `14:00:00` },
  2: { start: "14:00:00", end: `14:30:00` },
  230: { start: "14:30:00", end: `15:00:00` },
  3: { start: "15:00:00", end: `15:30:00` },
  330: { start: "15:30:00", end: `16:00:00` },
  4: { start: "16:00:00", end: `16:30:00` },
  430: { start: "16:30:00", end: `17:00:00` },
  5: { start: "17:00:00", end: `17:30:00` },
  530: { start: "17:30:00", end: `18:00` },
  6: { start: "18:00:00", end: `18:30:00` },
  630: { start: "18:30:00", end: `19:00:00` },
  7: { start: "19:00:00", end: `19:30:00` },
  730: { start: "19:30:00", end: `20:00:00` },
  8: { start: "20:00:00", end: `20:30:00` },
  830: { start: "20:30:00", end: `21:00:00` },
  9: { start: "21:00:00", end: `21:30:00` },
};
const calculateTime = (data) => {
  const [hour, min] = data.split(":");
  return hour * 60 + parseInt(min);
};

const findTime = (meetingTime, data, currentDate) => {
  const timeRange = time?.[meetingTime];
  if (currentDate !== data?.date?.split("T")?.[0]) {
    return false;
  }
  const startTime = calculateTime(timeRange.start);
  const endTime = calculateTime(timeRange.end);
  const currentTime = calculateTime(moment().format("HH:mm"));
  if (currentTime >= startTime && currentTime <= endTime) {
    return true;
  } else {
    return false;
  }
};

const calculateTimeCount = (data) => {
  if (!data?.interviewDate) {
    return 0;
  }
  const currentTime = moment(new Date()); //now
  const [date] = data?.interviewDate?.split("T");
  const timeRange = data?.interviewTime;
  const examTime = moment(`${date}T${timeRange}`);
  const difference = currentTime.diff(examTime, "seconds");
  if (difference < 0) {
    return difference * -1;
  } else {
    return 0;
  }
};

const calculateTimeCount2 = (data) => {
  if (!data?.submissionDate) {
    return 0;
  }
  const currentTime = moment(new Date()); //now
  const [date] = data?.submissionDate?.split("T");
  const timeRange = data?.submissionTime;
  const examTime = moment(`${date}T${timeRange}`);
  const difference = currentTime.diff(examTime, "seconds");
  console.log(`difference`, difference);
  if (difference < 0) {
    return difference * -1;
  } else {
    return 0;
  }
};

const checkTimeGap = (start, end, checkGap = "days") => {
  return moment(end).diff(moment(start), checkGap);
};
const formatDate = (date, type = "YYYY-MM-DD") =>
  date ? moment(date).format(type) : "";

const userDateFormat = (date) =>
  date ? moment(date).format("MMM DD, YYYY") : "";

const fixedNumber = (number, point = 2) => {
  const num = number * 1;
  if (!number) {
    return 0;
  }
  return Number.isInteger(num) ? num : num.toFixed(point);
};

const addExtraDayInTime = (date, count, type = "days", format = "YYYY-MM-DD") =>
  moment(date).add(type, count).format(format);

const getDaysFromRange = (start, end) => {
  const checkDayGap = checkTimeGap(start, end);
  let currentDate = start;
  let days = [];
  for (let i = 0; i <= checkDayGap; i++) {
    days.push(currentDate);
    currentDate = moment(currentDate).add(1, "day").format("YYYY-MM-DD");
  }
  return days;
};

const firstDateFormMonth = (month) => {
  return moment(moment(new Date(month)).toDate("month")).format("YYYY-MM-DD");
};

const lastDateFormMonth = (month) => {
  return moment(new Date(month)).endOf("month").format("YYYY-MM-DD");
};

const formatMonth = (month) => {
  return moment(month).format("MMM, YYYY");
};

export {
  formatTime,
  reverseTime,
  convert12To24,
  convert24To12,
  formatAMPM,
  UTCtoLocal,
  getUTCTime,
  timeDate,
  UTCtoLocalTime,
  UTCtoLocalPlus,
  findTime,
  calculateTimeCount,
  checkTimeGap,
  formatDate,
  userDateFormat,
  fixedNumber,
  calculateTimeCount2,
  getDaysFromRange,
  addExtraDayInTime,
  firstDateFormMonth,
  lastDateFormMonth,
  formatMonth,
};
