import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addAreaData,
  deleteArea,
  deleteAreaFailure,
  deleteAreaSuccess,
  getAreaList,
  getAreaListFailure,
  getAreaListSuccess,
  getSingleArea,
  getSingleAreaFailure,
  getSingleAreaSuccess,
} from "./AreaSlice";

function* onGetArea({
  payload: { page = 0, limit = 0, name = "", regionId = "" },
}) {
  try {
    const url = `/area/data?limit=${limit}&pageNo=${page}&name=${name}&regionId=${regionId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAreaListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get area failed";
    yield put(getAreaListFailure({ message }));
  }
}

function* onAddArea({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/area/${id}` : `/area`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Area ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Area ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleArea({ payload: { id } }) {
  try {
    const url = `/area/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleAreaSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single area failed";
    toaster("error", message);
    yield put(getSingleAreaFailure({ message }));
  }
}

function* onDeleteArea({ payload: { id, successCallBack } }) {
  try {
    const url = `/area/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteAreaSuccess(response));
      toaster("success", "Area deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Area deleted failed";
    toaster("error", message);
    yield put(deleteAreaFailure({ message }));
  }
}

export function* areaSaga() {
  yield takeEvery(getAreaList.type, onGetArea);
  yield takeEvery(addAreaData.type, onAddArea);
  yield takeEvery(getSingleArea.type, onGetSingleArea);
  yield takeEvery(deleteArea.type, onDeleteArea);
}
