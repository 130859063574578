import adminDashboardSlice from "./Admin/AdminDashboard/adminDashboardSlice";
import AppraisalReportSlice from "./Admin/AppraisalReport/AppraisalReportSlice";
import dailyAttendanceReportSlice from "./Admin/Attendance/attendanceSlice";
import CategoryItemSlice from "./Admin/CategoryItem/CategoryItemSlice";
import CorporateQuestionSlice from "./Admin/CorporateQuestions/CorporateQuestionSlice";
import HolidaysSlice from "./Admin/Holidays/HolidaysSlice";
import adminIncrementSlice from "./Admin/Increment/adminIncrementSlice";
import ItemSlice from "./Admin/Item/ItemSlice";
import KPISetupRequestSlice from "./Admin/KPISetupRequest/KPISetupRequestSlice";
import LevelSlice from "./Admin/Level/LevelSlice";
import PassageSlice from "./Admin/Passage/PassageSlice";
import performanceSlice from "./Admin/Performance/performanceSlice";
import KpiSetupReportSlice from "./Admin/PerformanceManagement/KpiSetupReport/KpiSetupReportSlice";
import openKpiSetupSlice from "./Admin/PerformanceManagement/OpenKpiSetup/openKpiSetupSlice";
import adminPromotionSlice from "./Admin/Promotion/PromotionSlice";
import QuestionSlice from "./Admin/Questions/QuestionSlice";
import ServiceSlice from "./Admin/Service/ServiceSlice";
import TaxSlice from "./Admin/Tax/TaxSlice";
import AreaSlice from "./Admin/setting/Area/AreaSlice";
import EmployeeCategorySlice from "./Admin/setting/EmployeeCategory/EmployeeCategorySlice";
import LocationSlice from "./Admin/setting/Location/LocationSlice";
import RegionSlice from "./Admin/setting/Region/RegionSlice";
import SubUnitSlice from "./Admin/setting/SubUnit/SubUnitSlice";
import UnitSlice from "./Admin/setting/Unit/UnitSlice";
import CorporateExamSlice from "./ApplicantPanel/CorporateExam/CorporateExamSlice";
import ApplicantSlice from "./ApplicantPanel/Dashboard/ApplicantSlice";
import ApplicantProfileSlice from "./ApplicantPanel/Profile/ApplicantProfileSlice";
import AssessmentSlice from "./Assessment/AssessmentSlice";
import loginSlice from "./Auth/Login/loginSlice";
import BankSlice from "./Bank/BankSlice";
import CareerSlice from "./Career/CareerSlice";
import ConcernCompanySlice from "./ConcernCompany/ConcernCompanySlice";
import DepartmentSlice from "./Department/DepartmentSlice";
import DesignationSlice from "./Designation/DesignationSlice";
import EmployeeSlice from "./Employee/EmployeeSlice";
import EmployeeAssessmentSlice from "./EmployeePanel/Assessment/AssessmentSlice";
import attendanceSlice from "./EmployeePanel/Attendance/attendanceSlice";
import IncrementSlice from "./EmployeePanel/Increment/IncrementSlice";
import performanceRattingSlice from "./EmployeePanel/Increment/PerformanceRatingSlice";
import salaryIncrementSlice from "./EmployeePanel/Increment/salaryIncrementSlice";
import IntervieweeSlice from "./EmployeePanel/Interviewee/IntervieweeSlice";
import leaveSlice from "./EmployeePanel/Leave/leaveSlice";
import kpiSelectionSlice from "./EmployeePanel/Performance/kpiSelectionSlice";
import AppraisalReportEmployeeSlice from "./EmployeePanel/PerformanceManagement/AppraisalReport/AppraisalReportEmployeeSlice";
import EmployeeKpiSetupRequestSlice from "./EmployeePanel/PerformanceManagement/EmployeeKPISetupRequest/EmployeeKPISetupSlice";
import OwnAppraisalSlice from "./EmployeePanel/PerformanceManagement/OwnAppraisal/OwnAppraisalSlice";
import ownKpiSlice from "./EmployeePanel/PerformanceManagement/OwnKPI/ownKpiSlice";
import OwnPerformanceAppraisalSlice from "./EmployeePanel/PerformanceManagement/OwnPerformanceAppraisal/OwnPerformanceAppraisalSlice";
import TeamAppraisalsSlice from "./EmployeePanel/PerformanceManagement/TeamAppraisals/TeamAppraisalsSlice";
import TeamMemberKpiSlice from "./EmployeePanel/PerformanceManagement/TeamMemberKpi/TeamMemberKpiSlice";
import employeePromotionSlice from "./EmployeePanel/Promotion/PromotionSlice";
import userProfileSlice from "./EmployeePanel/UserProfile/userProfileSlice";
import filterSlice from "./Filter/filterSlice";
import AdminIntervieweeSlice from "./Interviewee/IntervieweeSlice";
import JobPostSlice from "./JobPost/JobPostSlice";
import KpiSlice from "./Kpi/KpiSlice";
import LayoutSlice from "./Layout/LayoutSlice";
import LeaveApplicationSlice from "./LeaveApplication/LeaveApplicationSlice";
import LeaveTypeSlice from "./LeaveType/LeaveTypeSlice";
import payrollSlice from "./Payroll/payrollSlice";
import salarySlice from "./Payroll/salarySlice";
import UserSlice from "./User/UserSlice";

export const rootReducers = {
  login: loginSlice,
  layout: LayoutSlice,
  user: UserSlice,
  concernCompany: ConcernCompanySlice,
  department: DepartmentSlice,
  designation: DesignationSlice,
  bank: BankSlice,
  career: CareerSlice,
  employee: EmployeeSlice,
  leaveType: LeaveTypeSlice,
  holidays: HolidaysSlice,
  leaveApplication: LeaveApplicationSlice,
  jobPost: JobPostSlice,
  question: QuestionSlice,
  assessment: AssessmentSlice,
  applicant: ApplicantSlice,
  applicantProfile: ApplicantProfileSlice,
  interviewee: IntervieweeSlice,
  adminInterviewee: AdminIntervieweeSlice,
  kpiData: KpiSlice,
  employeeAssessment: EmployeeAssessmentSlice,
  service: ServiceSlice,
  level: LevelSlice,
  passage: PassageSlice,
  corporateQuestion: CorporateQuestionSlice,
  corporateExam: CorporateExamSlice,
  item: ItemSlice,
  categoryItem: CategoryItemSlice,
  increment: IncrementSlice,
  performance: performanceSlice,
  performanceRatting: performanceRattingSlice,
  kpiSelection: kpiSelectionSlice,
  attendance: attendanceSlice,
  dailyAttendanceReport: dailyAttendanceReportSlice,
  employeePromotion: employeePromotionSlice,
  adminPromotion: adminPromotionSlice,
  tax: TaxSlice,
  filter: filterSlice,
  payroll: payrollSlice,
  salaryIncrement: salaryIncrementSlice,
  adminIncrement: adminIncrementSlice,
  leave: leaveSlice,
  userProfile: userProfileSlice,
  salary: salarySlice,
  adminDashboard: adminDashboardSlice,
  // performance management
  openKpiSetup: openKpiSetupSlice,
  employeeCategory: EmployeeCategorySlice,
  unit: UnitSlice,
  subUnit: SubUnitSlice,
  region: RegionSlice,
  area: AreaSlice,
  location: LocationSlice,
  kpiSetupRequest: KPISetupRequestSlice,
  kpiSetupReport: KpiSetupReportSlice,
  appraisalReport: AppraisalReportSlice,
  // performance management employee
  ownKpi: ownKpiSlice,
  teamMemberKpi: TeamMemberKpiSlice,
  ownPerformanceAppraisal: OwnPerformanceAppraisalSlice,
  employeeKpiSetupRequest: EmployeeKpiSetupRequestSlice,
  ownAppraisal: OwnAppraisalSlice,
  teamAppraisal: TeamAppraisalsSlice,
  appraisalReportEmployee: AppraisalReportEmployeeSlice,
};
