import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addPromotionUpdateEmployee,
  addTeamAppraisalData,
  addTeamMemberAppraisalFeedback,
  addTeamMemberAppraisalFeedbackSecondTier,
  approvedSecondTierManager,
  getTeamAppraisalList,
  getTeamAppraisalListFailure,
  getTeamAppraisalListSuccess,
} from "./TeamAppraisalsSlice";

function* onTeamAppraisal({
  payload: {
    performancePeriodId = "",
    appraisalStatus = "",
    lineManagerType = "",
    subordinateEmployeeId = "",
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/team-member-appraisal-list?performancePeriodId=${performancePeriodId}&appraisalStatus=${appraisalStatus}&lineManagerType=${lineManagerType}&subordinateEmployeeId=${subordinateEmployeeId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTeamAppraisalListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Team Appraisal Failed";
    yield put(getTeamAppraisalListFailure({ message }));
  }
}

function* onAddTeamAppraisal({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, status, id },
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/appraisal-edit-and-submit/${id}`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        status || `Team appraisal ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} team appraisal failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onAddTeamMemberFeedbackAppraisal({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/appraisal-reject-with-feedback/${id}`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Appraisal reject with feedback added successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Appraisal reject with feedback added failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onAddTeamMemberFeedbackAppraisalSecondTire({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/appraisal-reject-by-second-tier-line-manager/${id}`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Appraisal reject with feedback added successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Appraisal reject with feedback added failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onApprovedSecondTierManager({
  payload: {
    data,
    options: { toggle, id },
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/appraisal-approved-by-second-tier-line-manager/${id}`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Appraisal approved by Second tier line manager`);
      // yield setSubmitting(false);
      // yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Appraisal approved by Second tier line manager failed`;

    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onAddPromotionUpdate({
  payload: {
    data,
    options: { toggle, id },
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/promotion-recommendation-update/${id}`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Promotion recommendation updated`);
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Promotion recommendation updated failed`;

    toaster("error", message);
  }
}

export function* teamAppraisalSaga() {
  yield takeEvery(getTeamAppraisalList.type, onTeamAppraisal);
  yield takeEvery(addTeamAppraisalData.type, onAddTeamAppraisal);
  yield takeEvery(approvedSecondTierManager.type, onApprovedSecondTierManager);
  yield takeEvery(
    addTeamMemberAppraisalFeedbackSecondTier.type,
    onAddTeamMemberFeedbackAppraisalSecondTire
  );
  yield takeEvery(
    addTeamMemberAppraisalFeedback.type,
    onAddTeamMemberFeedbackAppraisal
  );
  yield takeEvery(addPromotionUpdateEmployee.type, onAddPromotionUpdate);
}
