import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addTeamMemberFeedbackKpi,
  addTeamMemberKpiData,
  deleteTeamMemberKpi,
  deleteTeamMemberKpiFailure,
  deleteTeamMemberKpiSuccess,
  getSingleTeamMemberKpi,
  getSingleTeamMemberKpiFailure,
  getSingleTeamMemberKpiSuccess,
  getTeamMemberKpiList,
  getTeamMemberKpiListFailure,
  getTeamMemberKpiListSuccess,
} from "./TeamMemberKpiSlice";

function* onGetTeamMemberKpi({
  payload: {
    page = 0,
    limit = 0,
    performancePeriodId = "",
    lineManagerType = "",
    kpiSetupStatus = "",
    subordinateEmployeeId = "",
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/get-team-member-kpi-setup-list?performancePeriodId=${performancePeriodId}&pageNo=${page}&limit=${limit}&subordinateEmployeeId=${subordinateEmployeeId}&lineManagerType=${lineManagerType}&kpiSetupStatus=${kpiSetupStatus}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTeamMemberKpiListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Team member KPI failed";
    yield put(getTeamMemberKpiListFailure({ message }));
  }
}

function* onAddTeamMemberKpi({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/performance-rating/edit-and-submit/${id}`
      : `/employee-panel/performance-rating/edit-and-submit`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Team member KPI ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Team member KPI ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onAddTeamMemberFeedbackKpi({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/performance-rating/reject-with-feedback/${id}`
      : `/employee-panel/performance-rating/reject-with-feedback`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Team member KPI ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Team member KPI ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleTeamMemberKpi({ payload: { id } }) {
  try {
    const url = `/teamMemberKpi/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleTeamMemberKpiSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single team member KPI failed";
    toaster("error", message);
    yield put(getSingleTeamMemberKpiFailure({ message }));
  }
}

function* onDeleteTeamMemberKpi({ payload: { id, successCallBack } }) {
  try {
    const url = `/teamMemberKpi/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteTeamMemberKpiSuccess(response));
      toaster("success", "Team member KPI deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Team member KPI deleted failed";
    toaster("error", message);
    yield put(deleteTeamMemberKpiFailure({ message }));
  }
}

export function* teamMemberKpiSaga() {
  yield takeEvery(getTeamMemberKpiList.type, onGetTeamMemberKpi);
  yield takeEvery(addTeamMemberKpiData.type, onAddTeamMemberKpi);
  yield takeEvery(addTeamMemberFeedbackKpi.type, onAddTeamMemberFeedbackKpi);
  yield takeEvery(getSingleTeamMemberKpi.type, onGetSingleTeamMemberKpi);
  yield takeEvery(deleteTeamMemberKpi.type, onDeleteTeamMemberKpi);
}
