import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";

import { toaster } from "../../../../helpers/custom/Toast";
import {
  addEmployeeKpiSetupRequestData,
  deleteEmployeeKpiSetupRequest,
  deleteEmployeeKpiSetupRequestFailure,
  deleteEmployeeKpiSetupRequestSuccess,
  getAllKpi,
  getAllKpiFailure,
  getAllKpiSuccess,
  getEmployeeKpiSetupRequestList,
  getEmployeeKpiSetupRequestListFailure,
  getEmployeeKpiSetupRequestListSuccess,
  getSingleEmployeeKpiSetupRequest,
  getSingleEmployeeKpiSetupRequestFailure,
  getSingleEmployeeKpiSetupRequestSuccess,
} from "./EmployeeKPISetupSlice";

function* onEmployeeKpiSetupRequest({
  payload: { page = 0, limit = 0, performancePeriodId = "", year = "" },
}) {
  try {
    const url = `/employee-panel/performance-rating/kpi-setup-open-request-list?performancePeriodId=${performancePeriodId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getEmployeeKpiSetupRequestListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Open Kpi Setup Failed";
    yield put(getEmployeeKpiSetupRequestListFailure({ message }));
  }
}

function* onAddEmployeeKpiSetupRequest({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/performance-rating/create-kpi-setup-open-request/${id}`
      : `/employee-panel/performance-rating/create-kpi-setup-open-request`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `KPI setup request ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} KPI setup request failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleEmployeeKpiSetupRequest({ payload: { id } }) {
  try {
    const url = `/performanceRequest/get-performance-period-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleEmployeeKpiSetupRequestSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single own KPI failed";
    toaster("error", message);
    yield put(getSingleEmployeeKpiSetupRequestFailure({ message }));
  }
}

function* onGetAllKpi({ payload: { page = 0, limit = 0 } }) {
  // TODO:
  try {
    const url = `/performanceRequest/all-kpi-list/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAllKpiSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get all KPI failed";
    toaster("error", message);
    yield put(getAllKpiFailure({ message }));
  }
}

function* onDeleteEmployeeKpiSetupRequest({
  payload: { id, successCallBack },
}) {
  try {
    const url = `/performanceRequest/${id}`; // TODO:
    const response = yield call(del, url);
    if (response) {
      yield put(deleteEmployeeKpiSetupRequestSuccess(response));
      toaster("success", "Open own KPI deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete own KPI failed";
    toaster("error", message);
    yield put(deleteEmployeeKpiSetupRequestFailure({ message }));
  }
}

export function* employeeKpiSetupRequestSaga() {
  yield takeEvery(
    getEmployeeKpiSetupRequestList.type,
    onEmployeeKpiSetupRequest
  );
  yield takeEvery(
    addEmployeeKpiSetupRequestData.type,
    onAddEmployeeKpiSetupRequest
  );
  yield takeEvery(
    getSingleEmployeeKpiSetupRequest.type,
    onGetSingleEmployeeKpiSetupRequest
  );
  yield takeEvery(getAllKpi.type, onGetAllKpi);
  yield takeEvery(
    deleteEmployeeKpiSetupRequest.type,
    onDeleteEmployeeKpiSetupRequest
  );
}
