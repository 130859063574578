import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeKpiSetupRequestList: {},
  loading: false,
  error: "",
  singleEmployeeKpiSetupRequest: {},
  adding: false,
  singleLoading: false,
};

const employeeKpiSetupRequestSlice = createSlice({
  name: "employeeKpiSetupRequest",
  initialState: initialState,
  reducers: {
    storeAppraisal: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getEmployeeKpiSetupRequestList: (state, action) => {
      state.loading = true;
    },
    getEmployeeKpiSetupRequestListSuccess: (state, action) => {
      state.employeeKpiSetupRequestList = action.payload;
      state.loading = false;
    },
    getEmployeeKpiSetupRequestListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addEmployeeKpiSetupRequestData: (state, action) => {},
    getSingleEmployeeKpiSetupRequest: (state, action) => {
      state.singleLoading = true;
    },
    getSingleEmployeeKpiSetupRequestSuccess: (state, action) => {
      state.singleEmployeeKpiSetupRequest = action.payload;
      state.singleLoading = false;
    },
    getSingleEmployeeKpiSetupRequestFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },
    getAllKpi: (state, action) => {
      state.allKpiLoading = true;
    },
    getAllKpiSuccess: (state, action) => {
      state.allKpiList = action.payload;
      state.allKpiLoading = false;
    },
    getAllKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.allKpiLoading = false;
    },
    deleteEmployeeKpiSetupRequest: (state, action) => {
      state.adding = true;
    },
    deleteEmployeeKpiSetupRequestSuccess: (state, action) => {
      state.adding = false;
    },
    deleteEmployeeKpiSetupRequestFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  resetEmployeeKpiSetupRequest,
  storeAppraisal,
  getEmployeeKpiSetupRequestList,
  getEmployeeKpiSetupRequestListSuccess,
  getEmployeeKpiSetupRequestListFailure,
  addEmployeeKpiSetupRequestData,
  getSingleEmployeeKpiSetupRequest,
  getSingleEmployeeKpiSetupRequestSuccess,
  getSingleEmployeeKpiSetupRequestFailure,
  deleteEmployeeKpiSetupRequest,
  deleteEmployeeKpiSetupRequestSuccess,
  deleteEmployeeKpiSetupRequestFailure,
  getAllKpi,
  getAllKpiSuccess,
  getAllKpiFailure,
} = employeeKpiSetupRequestSlice.actions;

export default employeeKpiSetupRequestSlice.reducer;
