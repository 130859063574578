import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import { formatDate } from "../../../../helpers/custom/time";
import {
  addOpenAppraisalData,
  addOpenAppraisalDataFail,
  addOpenAppraisalDataSuccess,
  addOpenKpiSetupData,
  addOpenKpiSetupDataFail,
  addOpenKpiSetupDataSuccess,
  deleteOpenKpiSetup,
  deleteOpenKpiSetupFailure,
  deleteOpenKpiSetupSuccess,
  getOpenKpiSetupList,
  getOpenKpiSetupListFailure,
  getOpenKpiSetupListSuccess,
  getSingleOpenKpiSetup,
  getSingleOpenKpiSetupFailure,
  getSingleOpenKpiSetupSuccess,
  reOpenKpiSetupData,
  reOpenKpiSetupDataFail,
  reOpenKpiSetupDataSuccess,
  storeAppraisal,
} from "./openKpiSetupSlice";

function* onOpenKpiSetup({
  payload: { page = 0, limit = 0, planPeriod = "", planType = "", year = "" },
}) {
  try {
    const url = `/performance-period/data/?limit=${limit}&pageNo=${page}&planPeriod=${planPeriod}&planType=${planType}&year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getOpenKpiSetupListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Open Kpi Setup Failed";
    yield put(getOpenKpiSetupListFailure({ message }));
  }
}

function* onAddOpenKpiSetup({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/performance-period/${id}` : `/performance-period/`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Open KPI Setup ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield put(addOpenKpiSetupDataSuccess());
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Open KPI Setup Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    yield put(addOpenKpiSetupDataFail());
  }
}

function* onAddOpenAppraisal({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/performance-period/open-appraisal/${id}`
      : `/performance-period/open-appraisal/`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Open Appraisal ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      yield put(addOpenAppraisalDataSuccess());
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} Open Appraisal Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    yield put(addOpenAppraisalDataFail());
  }
}

function* onGetSingleKpiSetup({ payload: { id, selectedData } }) {
  try {
    const url = `/performance-period/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      response?.performancePeriod?.departmentPeriod?.forEach((el) => {
        if (selectedData?.length) {
          // console.log(selectedData);

          const matchingItem = selectedData?.find((el2) => {
            return el2?.departmentId?._id === el?.departmentId?._id;
          });
          el.selected = matchingItem ? true : false;
          el.appraisalDueDate = matchingItem?.appraisalDueDate
            ? formatDate(matchingItem?.appraisalDueDate, "YYYY-MM-DD")
            : "";
          // if (matchingItem) {
          // } else {
          //   el.selected = false;
          //   el.kpiSetupDueDate = "";
          // }
        } else {
          el.selected = false;
          el.appraisalDueDate = "";
        }
      });

      // console.log(response?.performancePeriod?.departmentPeriod);

      yield put(getSingleOpenKpiSetupSuccess(response?.item));
      yield put(
        storeAppraisal({
          name: "openAppraisalList",
          data: response?.performancePeriod?.departmentPeriod,
        })
      );
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Single KPI Setup Failed";
    toaster("error", message);
    yield put(getSingleOpenKpiSetupFailure({ message }));
  }
}

function* onDeleteKpiSetup({ payload: { id, successCallBack } }) {
  try {
    const url = `/performance-period/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteOpenKpiSetupSuccess(response));
      toaster("success", "Open KPI Setup Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete KPI Setup failed";
    toaster("error", message);
    yield put(deleteOpenKpiSetupFailure({ message }));
  }
}

function* onReOpenKpiSetup({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = `/performance-period/reopen-kpi-setup/${id}`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Re-open KPI Setup Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(reOpenKpiSetupDataSuccess());
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Re-open KPI Setup Failed`;
    toaster("error", message);
    yield setSubmitting(false);
    yield put(reOpenKpiSetupDataFail());
  }
}

export function* openKpiSetupSaga() {
  yield takeEvery(getOpenKpiSetupList.type, onOpenKpiSetup);
  yield takeEvery(addOpenKpiSetupData.type, onAddOpenKpiSetup);
  yield takeEvery(getSingleOpenKpiSetup.type, onGetSingleKpiSetup);
  yield takeEvery(addOpenAppraisalData.type, onAddOpenAppraisal);
  yield takeEvery(deleteOpenKpiSetup.type, onDeleteKpiSetup);
  yield takeEvery(reOpenKpiSetupData.type, onReOpenKpiSetup);
}
