import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addUnitData,
  deleteUnit,
  deleteUnitFailure,
  deleteUnitSuccess,
  getSingleUnit,
  getSingleUnitFailure,
  getSingleUnitSuccess,
  getUnitList,
  getUnitListFailure,
  getUnitListSuccess,
} from "./UnitSlice";

function* onGetUnit({
  payload: { page = 0, limit = 0, name = "", isArray = false, department = "" },
}) {
  try {
    const url = `/unit/data?limit=${limit}&pageNo=${page}&name=${name}&${
      isArray && department ? department : `department=${department}`
    }`;
    const response = yield call(get, url);
    if (response) {
      yield put(getUnitListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get unit failed";
    yield put(getUnitListFailure({ message }));
  }
}

function* onAddUnit({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/unit/${id}` : `/unit`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Unit ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Unit ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleUnit({ payload: { id } }) {
  try {
    const url = `/unit/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleUnitSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get single unit failed";
    toaster("error", message);
    yield put(getSingleUnitFailure({ message }));
  }
}

function* onDeleteUnit({ payload: { id, successCallBack } }) {
  try {
    const url = `/unit/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteUnitSuccess(response));
      toaster("success", "Unit deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Unit deleted failed";
    toaster("error", message);
    yield put(deleteUnitFailure({ message }));
  }
}

export function* unitSaga() {
  yield takeEvery(getUnitList.type, onGetUnit);
  yield takeEvery(addUnitData.type, onAddUnit);
  yield takeEvery(getSingleUnit.type, onGetSingleUnit);
  yield takeEvery(deleteUnit.type, onDeleteUnit);
}
