import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addRegionData,
  deleteRegion,
  deleteRegionFailure,
  deleteRegionSuccess,
  getRegionList,
  getRegionListFailure,
  getRegionListSuccess,
  getSingleRegion,
  getSingleRegionFailure,
  getSingleRegionSuccess,
} from "./RegionSlice";

function* onGetRegion({ payload: { page = 0, limit = 0, name = "" } }) {
  try {
    const url = `/region/data?limit=${limit}&pageNo=${page}&name=${name}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getRegionListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get region failed";
    yield put(getRegionListFailure({ message }));
  }
}

function* onAddRegion({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/region/${id}` : `/region`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Region ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Region ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleRegion({ payload: { id } }) {
  try {
    const url = `/region/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleRegionSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single region failed";
    toaster("error", message);
    yield put(getSingleRegionFailure({ message }));
  }
}

function* onDeleteRegion({ payload: { id, successCallBack } }) {
  try {
    const url = `/region/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteRegionSuccess(response));
      toaster("success", "Region deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Region deleted failed";
    toaster("error", message);
    yield put(deleteRegionFailure({ message }));
  }
}

export function* regionSaga() {
  yield takeEvery(getRegionList.type, onGetRegion);
  yield takeEvery(addRegionData.type, onAddRegion);
  yield takeEvery(getSingleRegion.type, onGetSingleRegion);
  yield takeEvery(deleteRegion.type, onDeleteRegion);
}
