import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appraisalReportEmployeeList: {},
  loading: false,
  error: "",
  singleAppraisalReportEmployee: {},
  singleLoading: false,
};

const appraisalReportEmployeeSlice = createSlice({
  name: "appraisalReportEmployee",
  initialState: initialState,
  reducers: {
    getAppraisalReportEmployeeList: (state, action) => {
      state.loading = true;
    },
    getAppraisalReportEmployeeListSuccess: (state, action) => {
      state.appraisalReportEmployeeList = action.payload;
      state.loading = false;
    },
    getAppraisalReportEmployeeListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAppraisalReportEmployeeData: (state, action) => {},
    getSingleAppraisalReportEmployee: (state, action) => {
      state.singleLoading = true;
    },
    getSingleAppraisalReportEmployeeSuccess: (state, action) => {
      state.singleAppraisalReportEmployee = action.payload;
      state.singleLoading = false;
    },
    getSingleAppraisalReportEmployeeFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },
  },
});

export const {
  getAppraisalReportEmployeeList,
  getAppraisalReportEmployeeListSuccess,
  getAppraisalReportEmployeeListFailure,
  addAppraisalReportEmployeeData,
  getSingleAppraisalReportEmployee,
  getSingleAppraisalReportEmployeeSuccess,
  getSingleAppraisalReportEmployeeFailure,
} = appraisalReportEmployeeSlice.actions;

export default appraisalReportEmployeeSlice.reducer;
