import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../helpers/api_helper";
import {
  getNewZealandDairyAbsenceRate,
  getNewZealandDairyAbsenceRateFail,
  getNewZealandDairyAbsenceRateSuccess,
  getNewZealandDairyAppraisalRating,
  getNewZealandDairyAppraisalRatingFail,
  getNewZealandDairyAppraisalRatingSuccess,
  getNewZealandDairyAppraisalStatus,
  getNewZealandDairyAppraisalStatusFail,
  getNewZealandDairyAppraisalStatusSuccess,
  getNewZealandDairyKpiSetupStatus,
  getNewZealandDairyKpiSetupStatusFail,
  getNewZealandDairyKpiSetupStatusSuccess,
  getNewZealandDairyOverallRating,
  getNewZealandDairyOverallRatingFail,
  getNewZealandDairyOverallRatingSuccess,
  getNewZealandDairyTopBanner,
  getNewZealandDairyTopBannerFail,
  getNewZealandDairyTopBannerSuccess,
  getNewZealandDairyTopBarAgeAnalysis,
  getNewZealandDairyTopBarAgeAnalysisFail,
  getNewZealandDairyTopBarAgeAnalysisSuccess,
  getNewZealandDairyTopBarAverageSalary,
  getNewZealandDairyTopBarAverageSalaryFail,
  getNewZealandDairyTopBarAverageSalarySuccess,
  getNewZealandDairyTopBarEmployee,
  getNewZealandDairyTopBarEmployeeFail,
  getNewZealandDairyTopBarEmployeeSuccess,
  getNewZealandDairyTopBarGeneralStatistics,
  getNewZealandDairyTopBarGeneralStatisticsFail,
  getNewZealandDairyTopBarGeneralStatisticsSuccess,
  getNewZealandDairyTopBarJobType,
  getNewZealandDairyTopBarJobTypeFail,
  getNewZealandDairyTopBarJobTypeSuccess,
  getRecruitmentStage,
  getRecruitmentStageFail,
  getRecruitmentStageSuccess,
  getRequest,
  getRequestFail,
  getRequestSuccess,
  getStuffTurnOver,
  getStuffTurnOverFail,
  getStuffTurnOverSuccess,
  getTopBanner,
  getTopBannerFail,
  getTopBannerSuccess,
  getTypeOfEmployee,
  getTypeOfEmployeeFail,
  getTypeOfEmployeeSuccess,
} from "./adminDashboardSlice";

function* onGetRecruitmentStage() {
  try {
    const url = "/dashboard/recruitment-stage";
    const response = yield call(get, url);
    if (response) {
      yield put(getRecruitmentStageSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Recruitment Stage Failed!";
    yield put(getRecruitmentStageFail({ message }));
  }
}

function* onGetTypeOfEmployee() {
  try {
    const url = "/dashboard/type-of-employee";
    const response = yield call(get, url);
    if (response) {
      yield put(getTypeOfEmployeeSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Type of Employee Failed!";
    yield put(getTypeOfEmployeeFail({ message }));
  }
}

function* onGetStuffTurnOver({ payload: { year = "" } }) {
  try {
    const selectedDate = year || new Date().getFullYear();
    const url = `/dashboard/staff-turnover?year=${selectedDate}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getStuffTurnOverSuccess(response?.emplyeeTurnover));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Type of stuff turn over Failed!";
    yield put(getStuffTurnOverFail({ message }));
  }
}

function* onGetRequest({ payload: { year = "" } }) {
  try {
    const url = `/dashboard/request?year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getRequestSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Request Failed!";
    yield put(getRequestFail({ message }));
  }
}

function* onGetTopBanner() {
  try {
    const url = `/dashboard/top-banner`;
    const response = yield call(get, url);
    if (response) {
      yield put(getTopBannerSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Top Banner Failed!";
    yield put(getTopBannerFail({ message }));
  }
}

// new zealand dairy
function* onGetNewZealandDairyTopBanner({
  payload: { planPeriodId = "", department = "", designation = "", unit = "" },
}) {
  try {
    const url = `/dashboard/top-banner?department=${department}&designation=${designation}&unit=${unit}`;
    const response = yield call(get, url, {
      params: {
        planPeriodId,
      },
    });
    if (response) {
      yield put(getNewZealandDairyTopBannerSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Top Banner Failed!";
    yield put(getNewZealandDairyTopBannerFail({ message }));
  }
}

//======== new zealand dairy last update
function* onGetNewZealandDairyTopBarEmployee({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/employee-counting-by-gender?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getNewZealandDairyTopBarEmployeeSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Employee Failed!";
    yield put(getNewZealandDairyTopBarEmployeeFail({ message }));
  }
}

function* onGetNewZealandDairyTopBarJobType({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/employee-percentage-by-job-type?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getNewZealandDairyTopBarJobTypeSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Job Type Failed!";
    yield put(getNewZealandDairyTopBarJobTypeFail({ message }));
  }
}

function* onGetNewZealandDairyTopBarAgeAnalysis({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/age-analysis?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getNewZealandDairyTopBarAgeAnalysisSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Age Analysis Failed!";
    yield put(getNewZealandDairyTopBarAgeAnalysisFail({ message }));
  }
}

function* onGetNewZealandDairyTopBarAverageSalary({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/average-salary?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getNewZealandDairyTopBarAverageSalarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Age Analysis Failed!";
    yield put(getNewZealandDairyTopBarAverageSalaryFail({ message }));
  }
}

function* onGetNewZealandDairyTopBarGeneralStatistics({
  payload: {
    planPeriodId = "",
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    year = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/general-statistics?gender=${gender}&year=${year}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url, {
      params: {
        planPeriodId,
      },
    });
    if (response) {
      yield put(getNewZealandDairyTopBarGeneralStatisticsSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Age Analysis Failed!";
    yield put(getNewZealandDairyTopBarGeneralStatisticsFail({ message }));
  }
}

// dashboard main area
function* onGetNewZealandDairyKpiSetupStatus({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    planPeriodId = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/kpi-setup-status?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url, {
      params: {
        planPeriodId,
      },
    });
    if (response) {
      yield put(getNewZealandDairyKpiSetupStatusSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get KPI Setup Status Failed!";
    yield put(getNewZealandDairyKpiSetupStatusFail({ message }));
  }
}

// dashboard main area
function* onGetNewZealandDairyAppraisalStatus({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    planPeriodId = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/appraisal-status?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url, {
      params: {
        planPeriodId,
      },
    });
    if (response) {
      yield put(getNewZealandDairyAppraisalStatusSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Appraisal Status Failed!";
    yield put(getNewZealandDairyAppraisalStatusFail({ message }));
  }
}

function* onGetNewZealandDairyAbsenceRate({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    year = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/absence-rate?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&year=${year}&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getNewZealandDairyAbsenceRateSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Absence rate Failed!";
    yield put(getNewZealandDairyAbsenceRateFail({ message }));
  }
}

function* onGetNewZealandDairyAppraisalRating({
  payload: {
    planPeriodId = "",
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/average-appraisal-rating?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url, {
      params: {
        planPeriodId,
      },
    });
    if (response) {
      yield put(getNewZealandDairyAppraisalRatingSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get average appraisal rating failed!";
    yield put(getNewZealandDairyAppraisalRatingFail({ message }));
  }
}

function* onGetNewZealandDairyOverallRating({
  payload: {
    department = "",
    unit = "",
    subUnit = "",
    location = "",
    employeeCategory = "",
    planPeriodId = "",
    gender = "",
    isLineManager = null,
    isSecondTierLineManager = null,
    userId = "",
  },
}) {
  try {
    const url = `/dashboard/average-overall-rating?gender=${gender}&${
      department || `department=${department}`
    }&${unit || `unit=${unit}`}&${subUnit || `subUnit=${subUnit}`}&${
      location || `location=${location}`
    }&${
      employeeCategory || `employeeCategory=${employeeCategory}`
    }&isLineManager=${isLineManager}&isSecondTierLineManager=${isSecondTierLineManager}&userId=${userId}`;
    const response = yield call(get, url, {
      params: {
        planPeriodId,
      },
    });
    if (response) {
      yield put(getNewZealandDairyOverallRatingSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get overall appraisal rating failed!";
    yield put(getNewZealandDairyOverallRatingFail({ message }));
  }
}

export function* adminDashboardSaga() {
  yield takeEvery(getRecruitmentStage.type, onGetRecruitmentStage);
  yield takeEvery(getTypeOfEmployee.type, onGetTypeOfEmployee);
  yield takeEvery(getStuffTurnOver.type, onGetStuffTurnOver);
  yield takeEvery(getRequest.type, onGetRequest);
  yield takeEvery(getTopBanner.type, onGetTopBanner);
  yield takeEvery(
    getNewZealandDairyTopBarEmployee.type,
    onGetNewZealandDairyTopBarEmployee
  );
  yield takeEvery(
    getNewZealandDairyTopBarJobType.type,
    onGetNewZealandDairyTopBarJobType
  );
  yield takeEvery(
    getNewZealandDairyTopBarAgeAnalysis.type,
    onGetNewZealandDairyTopBarAgeAnalysis
  );
  yield takeEvery(
    getNewZealandDairyTopBarAverageSalary.type,
    onGetNewZealandDairyTopBarAverageSalary
  );
  yield takeEvery(
    getNewZealandDairyTopBarGeneralStatistics.type,
    onGetNewZealandDairyTopBarGeneralStatistics
  );
  yield takeEvery(
    getNewZealandDairyKpiSetupStatus.type,
    onGetNewZealandDairyKpiSetupStatus
  );
  yield takeEvery(
    getNewZealandDairyAbsenceRate.type,
    onGetNewZealandDairyAbsenceRate
  );
  yield takeEvery(
    getNewZealandDairyAppraisalRating.type,
    onGetNewZealandDairyAppraisalRating
  );
  yield takeEvery(
    getNewZealandDairyOverallRating.type,
    onGetNewZealandDairyOverallRating
  );
  yield takeEvery(
    getNewZealandDairyTopBanner.type,
    onGetNewZealandDairyTopBanner
  );
  yield takeEvery(
    getNewZealandDairyAppraisalStatus.type,
    onGetNewZealandDairyAppraisalStatus
  );
}
