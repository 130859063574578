import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addLeaveData,
  applicationReview,
  deleteLeave,
  deleteLeaveFailure,
  deleteLeaveSuccess,
  getLeaveList,
  getLeaveListFailure,
  getLeaveListSuccess,
} from "./leaveSlice";

function* onGetLeave({
  payload: {
    page = 1,
    limit = 10,
    leaveType = "",
    status = "Pending",
    employeeId = "",
    whichApplication = "",
    year = "",
  },
}) {
  try {
    const url = `/employee-panel/leave-application/data/?limit=${limit}&pageNo=${page}&leaveType=${leaveType}&employeeId=${employeeId}&status=${status}&whichApplication=${whichApplication}${
      year ? `&year=${year}` : ""
    }`;
    const response = yield call(get, url);
    if (response) {
      yield put(getLeaveListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get salary leave failed";
    yield put(getLeaveListFailure({ message }));
  }
}

function* onAddLeave({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id = "" },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/leave-application/${id}`
      : `/employee-panel/leave-application/`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Leave ${id ? "Update" : "Added"} Successfully`);
      //   handleSubmitting(true);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    // console.log(error);
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Leave Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onApplicationReview({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id = "" },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/leave-application/review/${id}`
      : `/employee-panel/leave-application/review`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Leave Application ${
          id && data?.status === "Approved" ? "Approved" : "Rejected"
        } `
      );
      //   handleSubmitting(true);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    // console.log(error);
    const message =
      error?.response?.data?.message || `${id ? "Update" : "Add"} Leave Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onDeleteLeave({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-panel/leave-application/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteLeaveSuccess(response));
      toaster("success", "Leave Deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete salary leave failed";
    toaster("error", message);
    yield put(deleteLeaveFailure({ message }));
  }
}

export function* leaveSaga() {
  yield takeEvery(getLeaveList.type, onGetLeave);
  yield takeEvery(addLeaveData.type, onAddLeave);
  yield takeEvery(applicationReview.type, onApplicationReview);
  yield takeEvery(deleteLeave.type, onDeleteLeave);
}
