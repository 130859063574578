import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kpiSetupRequestList: {},
  loading: false,
  error: "",
  singleKpiSetupRequest: {},
  adding: false,
  singleLoading: false,
  singleFeedbackLoading: false,
  singleKpiSetupFeedbackRequest: {},
};

const kpiSetupRequestSlice = createSlice({
  name: "kpiSetupRequest",
  initialState: initialState,
  reducers: {
    storeAppraisal: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getKpiSetupRequestList: (state, action) => {
      state.loading = true;
    },
    getKpiSetupRequestListSuccess: (state, action) => {
      state.kpiSetupRequestList = action.payload;
      state.loading = false;
    },
    getKpiSetupRequestListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addKpiSetupRequestData: (state, action) => {},
    addAcceptRequestData: (state, action) => {},
    addRejectRequestData: (state, action) => {},
    updateStatusFeedback: (state, action) => {},
    getSingleKpiSetupRequest: (state, action) => {
      state.singleLoading = true;
    },
    getSingleKpiSetupRequestSuccess: (state, action) => {
      state.singleKpiSetupRequest = action.payload;
      state.singleLoading = false;
    },
    getSingleKpiSetupRequestFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },

    getSingleKpiSetupFeedbackRequest: (state, action) => {
      state.singleFeedbackLoading = true;
    },
    getSingleKpiSetupRequestFeedbackSuccess: (state, action) => {
      state.singleKpiSetupFeedbackRequest = action.payload;
      state.singleFeedbackLoading = false;
    },
    getSingleKpiSetupRequestFeedbackFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleFeedbackLoading = false;
    },
    getAllKpi: (state, action) => {
      state.allKpiLoading = true;
    },
    getAllKpiSuccess: (state, action) => {
      state.allKpiList = action.payload;
      state.allKpiLoading = false;
    },
    getAllKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.allKpiLoading = false;
    },
    deleteKpiSetupRequest: (state, action) => {
      state.adding = true;
    },
    deleteKpiSetupRequestSuccess: (state, action) => {
      state.adding = false;
    },
    deleteKpiSetupRequestFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  resetKpiSetupRequest,
  storeAppraisal,
  getKpiSetupRequestList,
  getKpiSetupRequestListSuccess,
  getKpiSetupRequestListFailure,
  addKpiSetupRequestData,
  getSingleKpiSetupRequest,
  getSingleKpiSetupRequestSuccess,
  getSingleKpiSetupRequestFailure,
  deleteKpiSetupRequest,
  deleteKpiSetupRequestSuccess,
  deleteKpiSetupRequestFailure,
  getAllKpi,
  getAllKpiSuccess,
  getAllKpiFailure,
  addAcceptRequestData,
  addRejectRequestData,
  getSingleKpiSetupFeedbackRequest,
  getSingleKpiSetupRequestFeedbackSuccess,
  getSingleKpiSetupRequestFeedbackFailure,
  updateStatusFeedback,
} = kpiSetupRequestSlice.actions;

export default kpiSetupRequestSlice.reducer;
