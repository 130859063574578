import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addAcceptRequestData,
  addKpiSetupRequestData,
  addRejectRequestData,
  deleteKpiSetupRequest,
  deleteKpiSetupRequestFailure,
  deleteKpiSetupRequestSuccess,
  getAllKpi,
  getAllKpiFailure,
  getAllKpiSuccess,
  getKpiSetupRequestList,
  getKpiSetupRequestListFailure,
  getKpiSetupRequestListSuccess,
  getSingleKpiSetupFeedbackRequest,
  getSingleKpiSetupRequest,
  getSingleKpiSetupRequestFailure,
  getSingleKpiSetupRequestFeedbackFailure,
  getSingleKpiSetupRequestFeedbackSuccess,
  getSingleKpiSetupRequestSuccess,
  updateStatusFeedback,
} from "./KPISetupRequestSlice";

function* onKpiSetupRequest({
  payload: {
    page = 0,
    limit = 0,
    performancePeriodId = "",
    department = "",
    designation = "",
    status = "",
  },
}) {
  try {
    const url = `/performance-rating/kpi-setup-open-request-list/?limit=${limit}&pageNo=${page}&performancePeriodId=${performancePeriodId}&department=${department}&designation=${designation}&status=${status}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getKpiSetupRequestListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get KPI setup request failed";
    yield put(getKpiSetupRequestListFailure({ message }));
  }
}

function* onAcceptRequest({
  payload: {
    data,
    options: { toggle, id },
  },
}) {
  try {
    const url = `/performance-rating/accept-kpi-setup-open-request/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `KPI request accepted`);
      // yield setSubmitting(false);
      // yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `KPI request accept failed`;
    toaster("error", message);
    // yield setSubmitting(false);
  }
}

function* onUpdateStatusFeedback({
  payload: {
    data,
    options: { toggle, id, setSubmitting, resetForm },
  },
}) {
  try {
    const url = `/performance-rating/status-update/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Update Status Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Update Status Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onRejectRequest({
  payload: {
    data,
    options: { toggle, id, setSubmitting, resetForm },
  },
}) {
  try {
    const url = `performance-rating/reject-kpi-setup-open-request/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `KPI request rejected`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `KPI request rejected failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onAddKpiSetupRequest({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/performance/request/${id}` : `/performance/request`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `KPI setup request ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} KPI setup request failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleKpiSetupRequest({ payload: { id } }) {
  try {
    const url = `/performanceRequest/get-performance-period-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleKpiSetupRequestSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single own KPI failed";
    toaster("error", message);
    yield put(getSingleKpiSetupRequestFailure({ message }));
  }
}

function* onGetSingleKpiSetupRequestFeedback({ payload: { id } }) {
  try {
    const url = `/performance-rating/kpi-setup-open-request-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleKpiSetupRequestFeedbackSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single own KPI failed";
    toaster("error", message);
    yield put(getSingleKpiSetupRequestFeedbackFailure({ message }));
  }
}

function* onGetAllKpi({ payload: { page = 0, limit = 0 } }) {
  // TODO:
  try {
    const url = `/performanceRequest/all-kpi-list/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAllKpiSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get all KPI failed";
    toaster("error", message);
    yield put(getAllKpiFailure({ message }));
  }
}

function* onDeleteKpiSetupRequest({ payload: { id, successCallBack } }) {
  try {
    const url = `/performanceRequest/${id}`; // TODO:
    const response = yield call(del, url);
    if (response) {
      yield put(deleteKpiSetupRequestSuccess(response));
      toaster("success", "Open own KPI deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete own KPI failed";
    toaster("error", message);
    yield put(deleteKpiSetupRequestFailure({ message }));
  }
}

export function* kpiSetupRequestSaga() {
  yield takeEvery(getKpiSetupRequestList.type, onKpiSetupRequest);
  yield takeEvery(addKpiSetupRequestData.type, onAddKpiSetupRequest);
  yield takeEvery(addAcceptRequestData.type, onAcceptRequest);
  yield takeEvery(addRejectRequestData.type, onRejectRequest);
  yield takeEvery(getSingleKpiSetupRequest.type, onGetSingleKpiSetupRequest);
  yield takeEvery(
    getSingleKpiSetupFeedbackRequest.type,
    onGetSingleKpiSetupRequestFeedback
  );
  yield takeEvery(getAllKpi.type, onGetAllKpi);
  yield takeEvery(deleteKpiSetupRequest.type, onDeleteKpiSetupRequest);
  yield takeEvery(updateStatusFeedback.type, onUpdateStatusFeedback);
}
