import { createSlice } from "@reduxjs/toolkit";
import { fixedNumber } from "../../../helpers/custom/time";
const month = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

const initialState = {
  recruitmentStageLoading: false,
  recruitmentStage: {},
  typeOfEmployeeLoading: false,
  typeOfEmployee: {},
  stuffTurnOverData: [],
  staffTurnOverLoading: false,
  requestLoading: false,
  request: {},
  topBannerLoading: false,
  topBanner: {},
  topNewZealandDairyBannerLoading: false,
  topNewZealandDairyBanner: {},
  error: "",
  topBannerEmployeeLoading: false,
  topBannerEmployeeList: {},
  topBannerJobTypeLoading: false,
  topBannerJobTypeList: {},
  topBannerAgeAnalysisLoading: false,
  topBannerAgeAnalysisList: {},
  topBannerAverageSalaryLoading: false,
  topBannerAverageSalaryList: {},
  topBannerGeneralStatisticsLoading: false,
  topBannerGeneralStatisticsList: {},
  kpiSetupStatusLoading: false,
  kpiSetupStatusList: {},
  appraisalStatusLoading: false,
  appraisalStatusList: {},
  absenceRateLoading: false,
  absenceRateList: {},
  appraisalRatingLoading: false,
  appraisalRatingList: {},
  overallRatingLoading: false,
  overallRatingList: {},
  //
  selectedMemberCounting: "",
  selectedJobType: "",
  selectedAge: "1-200",
  selectedAverageSalary: "1",
  selectedStatistics: "",
  statisticsLoading: false,
};

const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    storeAdminDashboard: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getRecruitmentStage: (state, action) => {
      state.recruitmentStageLoading = true;
    },
    getRecruitmentStageSuccess: (state, action) => {
      state.recruitmentStageLoading = false;
      state.recruitmentStage = action.payload;
    },
    getRecruitmentStageFail: (state, action) => {
      state.recruitmentStageLoading = false;
      state.error = action.payload?.message;
    },
    getTypeOfEmployee: (state, action) => {
      state.typeOfEmployeeLoading = true;
    },
    getTypeOfEmployeeSuccess: (state, action) => {
      state.typeOfEmployeeLoading = false;
      state.typeOfEmployee = action.payload;
    },
    getTypeOfEmployeeFail: (state, action) => {
      state.typeOfEmployeeLoading = false;
      state.error = action.payload?.message;
    },
    getStuffTurnOver: (state, action) => {
      state.staffTurnOverLoading = true;
    },
    getStuffTurnOverSuccess: (state, { payload }) => {
      let newData = [];
      payload.forEach((act, idx) => {
        const arr = newData?.length ? [...newData] : [...month];
        arr[act?._id - 1] = fixedNumber(act?.count * 1, 2);
        newData = arr;
      });
      state.staffTurnOverLoading = false;
      state.stuffTurnOverData = [
        { name: "Staff Turnover", data: payload?.length ? newData : month },
      ];
    },
    getStuffTurnOverFail: (state, action) => {
      state.staffTurnOverLoading = false;
      state.error = action.payload?.message;
    },
    getRequest: (state, action) => {
      state.requestLoading = true;
    },
    getRequestSuccess: (state, action) => {
      state.requestLoading = false;
      state.request = action.payload;
    },
    getRequestFail: (state, action) => {
      state.requestLoading = false;
      state.error = action.payload?.message;
    },
    getTopBanner: (state, action) => {
      state.topBannerLoading = true;
    },
    getTopBannerSuccess: (state, action) => {
      state.topBannerLoading = false;
      state.topBanner = action.payload;
    },
    getTopBannerFail: (state, action) => {
      state.topBannerLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyTopBanner: (state, action) => {
      state.topNewZealandDairyBannerLoading = true;
    },
    getNewZealandDairyTopBannerSuccess: (state, action) => {
      state.topNewZealandDairyBannerLoading = false;
      state.topNewZealandDairyBanner = action.payload;
    },
    getNewZealandDairyTopBannerFail: (state, action) => {
      state.topNewZealandDairyBannerLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyTopBarEmployee: (state, action) => {
      state.topBannerEmployeeLoading = true;
    },
    getNewZealandDairyTopBarEmployeeSuccess: (state, action) => {
      state.topBannerEmployeeLoading = false;
      state.topBannerEmployeeList = action.payload;
    },
    getNewZealandDairyTopBarEmployeeFail: (state, action) => {
      state.topBannerEmployeeLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyTopBarJobType: (state, action) => {
      state.topBannerJobTypeLoading = true;
    },
    getNewZealandDairyTopBarJobTypeSuccess: (state, action) => {
      state.topBannerJobTypeLoading = false;
      state.topBannerJobTypeList = action.payload;
    },
    getNewZealandDairyTopBarJobTypeFail: (state, action) => {
      state.topBannerJobTypeLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyTopBarAgeAnalysis: (state, action) => {
      state.topBannerAgeAnalysisLoading = true;
    },
    getNewZealandDairyTopBarAgeAnalysisSuccess: (state, action) => {
      state.topBannerAgeAnalysisLoading = false;
      state.topBannerAgeAnalysisList = action.payload;
    },
    getNewZealandDairyTopBarAgeAnalysisFail: (state, action) => {
      state.topBannerAgeAnalysisLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyTopBarAverageSalary: (state, action) => {
      state.topBannerAverageSalaryLoading = true;
    },
    getNewZealandDairyTopBarAverageSalarySuccess: (state, action) => {
      state.topBannerAverageSalaryLoading = false;
      state.topBannerAverageSalaryList = action.payload;
    },
    getNewZealandDairyTopBarAverageSalaryFail: (state, action) => {
      state.topBannerAverageSalaryLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyTopBarGeneralStatistics: (state, action) => {
      state.topBannerGeneralStatisticsLoading = true;
    },
    getNewZealandDairyTopBarGeneralStatisticsSuccess: (state, action) => {
      state.topBannerGeneralStatisticsLoading = false;
      state.topBannerGeneralStatisticsList = action.payload;
    },
    getNewZealandDairyTopBarGeneralStatisticsFail: (state, action) => {
      state.topBannerGeneralStatisticsLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyKpiSetupStatus: (state, action) => {
      state.kpiSetupStatusLoading = true;
    },
    getNewZealandDairyKpiSetupStatusSuccess: (state, action) => {
      state.kpiSetupStatusLoading = false;
      state.kpiSetupStatusList = action.payload;
    },
    getNewZealandDairyKpiSetupStatusFail: (state, action) => {
      state.kpiSetupStatusLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyAppraisalStatus: (state, action) => {
      state.appraisalStatusLoading = true;
    },
    getNewZealandDairyAppraisalStatusSuccess: (state, action) => {
      state.appraisalStatusLoading = false;
      state.appraisalStatusList = action.payload;
    },
    getNewZealandDairyAppraisalStatusFail: (state, action) => {
      state.appraisalStatusLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyAbsenceRate: (state, action) => {
      state.absenceRateLoading = true;
    },
    getNewZealandDairyAbsenceRateSuccess: (state, action) => {
      state.absenceRateLoading = false;
      state.absenceRateList = action.payload;
    },
    getNewZealandDairyAbsenceRateFail: (state, action) => {
      state.absenceRateLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyAppraisalRating: (state, action) => {
      state.appraisalRatingLoading = true;
    },
    getNewZealandDairyAppraisalRatingSuccess: (state, action) => {
      state.appraisalRatingLoading = false;
      state.appraisalRatingList = action.payload;
    },
    getNewZealandDairyAppraisalRatingFail: (state, action) => {
      state.appraisalRatingLoading = false;
      state.error = action.payload?.message;
    },
    getNewZealandDairyOverallRating: (state, action) => {
      state.overallRatingLoading = true;
    },
    getNewZealandDairyOverallRatingSuccess: (state, action) => {
      state.overallRatingLoading = false;
      state.overallRatingList = action.payload;
    },
    getNewZealandDairyOverallRatingFail: (state, action) => {
      state.overallRatingLoading = false;
      state.error = action.payload?.message;
    },

    resetData: (state) => {
      state.selectedJobType = "";
      state.selectedAge = "1-200";
      state.selectedMemberCounting = "";
      state.selectedAverageSalary = "1";
    },
  },
});

export const {
  storeAdminDashboard,
  getRecruitmentStage,
  getRecruitmentStageSuccess,
  getRecruitmentStageFail,
  getTypeOfEmployee,
  getTypeOfEmployeeSuccess,
  getTypeOfEmployeeFail,
  getStuffTurnOver,
  getStuffTurnOverSuccess,
  getStuffTurnOverFail,
  getRequest,
  getRequestSuccess,
  getRequestFail,
  getTopBanner,
  getTopBannerSuccess,
  getTopBannerFail,
  getNewZealandDairyTopBanner,
  getNewZealandDairyTopBannerSuccess,
  getNewZealandDairyTopBannerFail,
  getNewZealandDairyTopBarEmployee,
  getNewZealandDairyTopBarEmployeeSuccess,
  getNewZealandDairyTopBarEmployeeFail,
  getNewZealandDairyTopBarJobType,
  getNewZealandDairyTopBarJobTypeSuccess,
  getNewZealandDairyTopBarJobTypeFail,
  getNewZealandDairyTopBarAgeAnalysis,
  getNewZealandDairyTopBarAgeAnalysisSuccess,
  getNewZealandDairyTopBarAgeAnalysisFail,
  getNewZealandDairyTopBarAverageSalary,
  getNewZealandDairyTopBarAverageSalarySuccess,
  getNewZealandDairyTopBarAverageSalaryFail,
  getNewZealandDairyTopBarGeneralStatistics,
  getNewZealandDairyTopBarGeneralStatisticsSuccess,
  getNewZealandDairyTopBarGeneralStatisticsFail,
  getNewZealandDairyKpiSetupStatus,
  getNewZealandDairyKpiSetupStatusSuccess,
  getNewZealandDairyKpiSetupStatusFail,
  getNewZealandDairyAbsenceRate,
  getNewZealandDairyAbsenceRateSuccess,
  getNewZealandDairyAbsenceRateFail,
  getNewZealandDairyAppraisalRating,
  getNewZealandDairyAppraisalRatingSuccess,
  getNewZealandDairyAppraisalRatingFail,
  getNewZealandDairyOverallRating,
  getNewZealandDairyOverallRatingSuccess,
  getNewZealandDairyOverallRatingFail,
  resetData,
  getNewZealandDairyAppraisalStatus,
  getNewZealandDairyAppraisalStatusSuccess,
  getNewZealandDairyAppraisalStatusFail,
} = adminDashboardSlice.actions;

export default adminDashboardSlice.reducer;
