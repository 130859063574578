import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeList: {},
  employeeListVal: [],
  experienceListVal: [],
  listLoading: false,
  loading: false,
  currentTab: 0,
  profileTab: "Info",
  employeeId: "",
  error: "",
  singleEmployee: {},
  singleEducation: {},
  singleExperience: {},
  vacationData: {},
  vacationLoading: false,
  adding: false,
  educationList: [],
  searchEmployee: {},
  kpiList: [],
  syncLoading: false,
  syncData: {},
};

const employeeSlice = createSlice({
  name: "employee",
  initialState: initialState,
  reducers: {
    storeEmployee: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    storeEmployeeId: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    getEmployeeList: (state, action) => {
      state.loading = true;
      state.listLoading = true;
    },
    getEmployeeListSuccess: (state, action) => {
      state.employeeList = action.payload;
      state.loading = false;
      state.listLoading = false;
    },
    getEmployeeListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
      state.listLoading = false;
    },
    getEmployeeVacation: (state, action) => {
      state.vacationLoading = true;
    },
    getEmployeeVacationSuccess: (state, action) => {
      state.vacationData = action.payload;
      state.vacationLoading = false;
    },
    getEmployeeVacationFailure: (state, action) => {
      state.error = action.payload.message;
      state.vacationLoading = false;
    },
    addEmployeeData: (
      state,
      {
        payload: {
          options: { setSubmitting },
        },
      }
    ) => {
      state.adding = setSubmitting ? false : true;
    },
    getSingleEmployee: (state, action) => {
      state.loading = true;
    },
    getSingleEmployeeSuccess: (state, { payload }) => {
      state.singleEmployee = payload;
      state.employeeListVal = payload?.education || [];
      state.experienceListVal = payload?.experiences || [];
      if (payload?.kpi) {
        payload?.kpi?.forEach((el) => {
          el.selected = true;
        });
        state.kpiList = payload?.kpi;
      }
      state.loading = false;
    },
    getSingleEmployeeFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSearchEmployee: (state, action) => {
      state.loading = true;
    },
    getSearchEmployeeSuccess: (state, action) => {
      state.searchEmployee = action.payload;
      state.loading = false;
    },
    getSearchEmployeeFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSyncEmployee: (state, action) => {
      state.syncLoading = true;
    },
    getSyncEmployeeSuccess: (state, action) => {
      state.syncData = action.payload;
      state.syncLoading = false;
    },
    getSyncEmployeeFailure: (state, action) => {
      // state.error = action.payload.message;
      state.syncLoading = false;
    },

    deleteEmployee: (state, action) => {
      state.adding = true;
    },
    deleteEmployeeSuccess: (state, action) => {
      state.adding = false;
    },
    deleteEmployeeFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    resetData: (state) => {
      state.singleEmployee = {};
      state.currentTab = 0;
    },
  },
});

export const {
  getEmployeeList,
  getEmployeeListSuccess,
  getEmployeeListFailure,
  addEmployeeData,
  getSingleEmployee,
  getSingleEmployeeSuccess,
  getSingleEmployeeFailure,
  deleteEmployee,
  deleteEmployeeSuccess,
  deleteEmployeeFailure,
  storeEmployee,
  getEmployeeVacation,
  getEmployeeVacationSuccess,
  getEmployeeVacationFailure,
  storeEmployeeId,
  getSearchEmployee,
  getSearchEmployeeSuccess,
  getSearchEmployeeFailure,
  resetData,
  getSyncEmployee,
  getSyncEmployeeSuccess,
  getSyncEmployeeFailure,
} = employeeSlice.actions;

export default employeeSlice.reducer;
