import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import { login } from "../../Auth/Login/loginSlice";
import {
  employeeForgotPassword,
  employeeResetPassword,
  employeeVacation,
  employeeVacationFailure,
  employeeVacationSuccess,
  getSingleUser,
  getSingleUserFailure,
  getSingleUserSuccess,
} from "./userProfileSlice";

function* onGetSingleUser({ payload: { id } }) {
  try {
    const url = `/employee-panel/profile/${id}`;
    const response = yield call(get, url);
    // console.log(response);
    if (response) {
      yield put(getSingleUserSuccess(response?.myProfile));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get User failed";
    yield put(getSingleUserFailure({ message }));
  }
}

function* onEmployeeVacation({ payload: { id, year = "" } }) {
  try {
    const currentyear = new Date().getFullYear();
    const vacationUrl = `/employee-panel/leave-application/leave-summary?employeeId=${id}&year=${
      year || currentyear
    }`;
    const vacation = yield call(get, vacationUrl);
    if (vacation) {
      yield put(employeeVacationSuccess(vacation));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single employee vacation failed";
    toaster("error", message);
    yield put(employeeVacationFailure({ message }));
  }
}

function* onEmployeeForgotPassword({
  payload: {
    data,
    options: { toggle, setIsLoading, resetForm, setSubmitting },
  },
}) {
  try {
    const url = `/employee-auth/forgot-password`;
    const response = yield call(post, url, data);

    if (response) {
      // toaster("success", `Token send your email, please check your email`);
      toggle(true);
      setIsLoading(false);
      yield setSubmitting(false);
      // yield resetForm();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Token send failed, please try again`;
    toaster("error", message);
    toggle(false);
    setIsLoading(false);
    yield setSubmitting(false);
  }
}

function* onEmployeeResetPassword({
  payload: {
    data,
    options: { token, history, setPasswordChangeLoading },
  },
}) {
  try {
    const url = `/employee-auth/reset-password/${token}`;
    const response = yield call(patch, url, data);

    if (response) {
      toaster("success", `Change password successfully`);

      yield put(
        login({
          history,
          data: {
            userName: response?.user?.employeeId,
            password: data?.password,
          },
          url: "/employee-auth/login",
          redirect: "/employee/dashboard",
        })
      );
      setPasswordChangeLoading(false);
    }
  } catch (error) {
    setPasswordChangeLoading(false);
    const message =
      error?.response?.data?.message ||
      `Change password failed, please try again`;
    toaster("error", message);
  }
}

export function* userProfileSaga() {
  yield takeEvery(getSingleUser.type, onGetSingleUser);
  yield takeEvery(employeeVacation.type, onEmployeeVacation);
  yield takeEvery(employeeForgotPassword.type, onEmployeeForgotPassword);
  yield takeEvery(employeeResetPassword.type, onEmployeeResetPassword);
}
