import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addSubUnitData,
  deleteSubUnit,
  deleteSubUnitFailure,
  deleteSubUnitSuccess,
  getSingleSubUnit,
  getSingleSubUnitFailure,
  getSingleSubUnitSuccess,
  getSubUnitList,
  getSubUnitListFailure,
  getSubUnitListSuccess,
} from "./SubUnitSlice";

function* onGetSubUnit({
  payload: { page = 0, limit = 0, name = "", isArray = false, unitId = "" },
}) {
  try {
    const url = `/sub-unit/data?limit=${limit}&pageNo=${page}&name=${name}&${
      isArray && unitId ? unitId : `unitId=${unitId}`
    }`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSubUnitListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get sub unit failed";
    yield put(getSubUnitListFailure({ message }));
  }
}

function* onAddSubUnit({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/sub-unit/${id}` : `/sub-unit`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Sub unit ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Sub unit ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleSubUnit({ payload: { id } }) {
  try {
    const url = `/sub-unit/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleSubUnitSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single sub unit failed";
    toaster("error", message);
    yield put(getSingleSubUnitFailure({ message }));
  }
}

function* onDeleteSubUnit({ payload: { id, successCallBack } }) {
  try {
    const url = `/sub-unit/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteSubUnitSuccess(response));
      toaster("success", "Sub unit deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Sub unit deleted failed";
    toaster("error", message);
    yield put(deleteSubUnitFailure({ message }));
  }
}

export function* subUnitSaga() {
  yield takeEvery(getSubUnitList.type, onGetSubUnit);
  yield takeEvery(addSubUnitData.type, onAddSubUnit);
  yield takeEvery(getSingleSubUnit.type, onGetSingleSubUnit);
  yield takeEvery(deleteSubUnit.type, onDeleteSubUnit);
}
