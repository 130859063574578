import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ownKpiList: {},
  loading: false,
  error: "",
  singleOwnKpi: {},
  adding: false,
  singleLoading: false,
  allKpiLoading: false,
  allKpiList: {},
};

const ownKpiSlice = createSlice({
  name: "ownKpi",
  initialState: initialState,
  reducers: {
    restKpi: (state, action) => {
      state.singleOwnKpi = {};
    },
    storeAppraisal: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getOwnKpiList: (state, action) => {
      state.loading = true;
    },
    getOwnKpiListSuccess: (state, action) => {
      state.ownKpiList = action.payload;
      state.loading = false;
    },
    getOwnKpiListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addOwnKpiData: (state, action) => {},
    getSingleOwnKpi: (state, action) => {
      state.singleLoading = true;
    },
    getSingleOwnKpiSuccess: (state, action) => {
      state.singleOwnKpi = action.payload;
      state.singleLoading = false;
    },
    getSingleOwnKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },
    getAllKpi: (state, action) => {
      state.allKpiLoading = true;
    },
    getAllKpiSuccess: (state, action) => {
      state.allKpiList = action.payload;
      state.allKpiLoading = false;
    },
    getAllKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.allKpiLoading = false;
    },
    deleteOwnKpi: (state, action) => {
      state.adding = true;
    },
    deleteOwnKpiSuccess: (state, action) => {
      state.adding = false;
    },
    deleteOwnKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  restKpi,
  resetOwnKpi,
  storeAppraisal,
  getOwnKpiList,
  getOwnKpiListSuccess,
  getOwnKpiListFailure,
  addOwnKpiData,
  getSingleOwnKpi,
  getSingleOwnKpiSuccess,
  getSingleOwnKpiFailure,
  deleteOwnKpi,
  deleteOwnKpiSuccess,
  deleteOwnKpiFailure,
  getAllKpi,
  getAllKpiSuccess,
  getAllKpiFailure,
} = ownKpiSlice.actions;

export default ownKpiSlice.reducer;
