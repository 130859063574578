import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subUnitList: {},
  loading: false,
  error: "",
  singleSubUnit: {},
  adding: false,
};

const subUnitSlice = createSlice({
  name: "subUnit",
  initialState: initialState,
  reducers: {
    getSubUnitList: (state, action) => {
      state.loading = true;
    },
    getSubUnitListSuccess: (state, action) => {
      state.subUnitList = action.payload;
      state.loading = false;
    },
    getSubUnitListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addSubUnitData: (state, action) => {},
    getSingleSubUnit: (state, action) => {
      state.loading = true;
    },
    getSingleSubUnitSuccess: (state, action) => {
      state.singleSubUnit = action.payload;
      state.loading = false;
    },
    getSingleSubUnitFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteSubUnit: (state, action) => {
      state.adding = true;
    },
    deleteSubUnitSuccess: (state, action) => {
      state.adding = false;
    },
    deleteSubUnitFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getSubUnitList,
  getSubUnitListSuccess,
  getSubUnitListFailure,
  addSubUnitData,
  getSingleSubUnit,
  getSingleSubUnitSuccess,
  getSingleSubUnitFailure,
  deleteSubUnit,
  deleteSubUnitSuccess,
  deleteSubUnitFailure,
} = subUnitSlice.actions;

export default subUnitSlice.reducer;
