import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appraisalReportList: {},
  loading: false,
  error: "",
  singleAppraisalReport: {},
  singleLoading: false,
};

const appraisalReportSlice = createSlice({
  name: "appraisalReport",
  initialState: initialState,
  reducers: {
    resetAppraisalReportData: (state, action) => {
      state.appraisalReportList = {};
      state.loading = false;
      state.error = "";
    },
    getAppraisalReportList: (state, action) => {
      state.loading = true;
    },
    getAppraisalReportListSuccess: (state, action) => {
      state.appraisalReportList = action.payload;
      state.loading = false;
    },
    getAppraisalReportListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getSingleAppraisalReport: (state, action) => {
      state.singleLoading = true;
    },
    getSingleAppraisalReportSuccess: (state, action) => {
      state.singleAppraisalReport = action.payload;
      state.singleLoading = false;
    },
    getSingleAppraisalReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleLoading = false;
    },
    addPromotionUpdate: (state, action) => {},
  },
});

export const {
  resetAppraisalReportData,
  getAppraisalReportList,
  getAppraisalReportListSuccess,
  getAppraisalReportListFailure,
  getSingleAppraisalReport,
  getSingleAppraisalReportSuccess,
  getSingleAppraisalReportFailure,
  addPromotionUpdate,
} = appraisalReportSlice.actions;

export default appraisalReportSlice.reducer;
