import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unitList: {},
  loading: false,
  error: "",
  singleUnit: {},
  adding: false,
};

const unitSlice = createSlice({
  name: "unit",
  initialState: initialState,
  reducers: {
    getUnitList: (state, action) => {
      state.loading = true;
    },
    getUnitListSuccess: (state, action) => {
      state.unitList = action.payload;
      state.loading = false;
    },
    getUnitListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addUnitData: (state, action) => {},
    getSingleUnit: (state, action) => {
      state.loading = true;
    },
    getSingleUnitSuccess: (state, action) => {
      state.singleUnit = action.payload;
      state.loading = false;
    },
    getSingleUnitFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteUnit: (state, action) => {
      state.adding = true;
    },
    deleteUnitSuccess: (state, action) => {
      state.adding = false;
    },
    deleteUnitFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getUnitList,
  getUnitListSuccess,
  getUnitListFailure,
  addUnitData,
  getSingleUnit,
  getSingleUnitSuccess,
  getSingleUnitFailure,
  deleteUnit,
  deleteUnitSuccess,
  deleteUnitFailure,
} = unitSlice.actions;

export default unitSlice.reducer;
