import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ownAppraisalList: {},
  loading: false,
  error: "",
};

const ownAppraisalSlice = createSlice({
  name: "ownAppraisal",
  initialState: initialState,
  reducers: {
    getOwnAppraisalList: (state, action) => {
      state.loading = true;
    },
    getOwnAppraisalListSuccess: (state, action) => {
      state.ownAppraisalList = action.payload;
      state.loading = false;
    },
    getOwnAppraisalListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addOwnAppraisalData: (state, action) => {},
  },
});

export const {
  getOwnAppraisalList,
  getOwnAppraisalListSuccess,
  getOwnAppraisalListFailure,
  addOwnAppraisalData,
} = ownAppraisalSlice.actions;

export default ownAppraisalSlice.reducer;
