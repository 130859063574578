import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addOwnPerformanceAppraisalData,
  deleteOwnPerformanceAppraisal,
  deleteOwnPerformanceAppraisalFailure,
  deleteOwnPerformanceAppraisalSuccess,
  getOwnPerformanceAppraisalList,
  getOwnPerformanceAppraisalListFailure,
  getOwnPerformanceAppraisalListSuccess,
  getSingleOwnPerformanceAppraisal,
  getSingleOwnPerformanceAppraisalFailure,
  getSingleOwnPerformanceAppraisalSuccess,
} from "./OwnPerformanceAppraisalSlice";

function* onGetOwnPerformanceAppraisal({
  payload: { page = 0, limit = 0, name = "", regionId = "" },
}) {
  try {
    const url = `/ownPerformanceAppraisal/data?limit=${limit}&pageNo=${page}&name=${name}&regionId=${regionId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getOwnPerformanceAppraisalListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get own performance appraisal failed";
    yield put(getOwnPerformanceAppraisalListFailure({ message }));
  }
}

function* onAddOwnPerformanceAppraisal({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/ownPerformanceAppraisal/${id}`
      : `/ownPerformanceAppraisal`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster(
        "success",
        `Own performance appraisal ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Own performance appraisal ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleOwnPerformanceAppraisal({ payload: { id } }) {
  try {
    const url = `/ownPerformanceAppraisal/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleOwnPerformanceAppraisalSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      "Get single own performance appraisal failed";
    toaster("error", message);
    yield put(getSingleOwnPerformanceAppraisalFailure({ message }));
  }
}

function* onDeleteOwnPerformanceAppraisal({
  payload: { id, successCallBack },
}) {
  try {
    const url = `/ownPerformanceAppraisal/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteOwnPerformanceAppraisalSuccess(response));
      toaster("success", "Own performance appraisal deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      "Own performance appraisal deleted failed";
    toaster("error", message);
    yield put(deleteOwnPerformanceAppraisalFailure({ message }));
  }
}

export function* ownPerformanceAppraisalSaga() {
  yield takeEvery(
    getOwnPerformanceAppraisalList.type,
    onGetOwnPerformanceAppraisal
  );
  yield takeEvery(
    addOwnPerformanceAppraisalData.type,
    onAddOwnPerformanceAppraisal
  );
  yield takeEvery(
    getSingleOwnPerformanceAppraisal.type,
    onGetSingleOwnPerformanceAppraisal
  );
  yield takeEvery(
    deleteOwnPerformanceAppraisal.type,
    onDeleteOwnPerformanceAppraisal
  );
}
