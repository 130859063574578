import { call, put, takeEvery } from "redux-saga/effects";
import { axiosApi, patch, post } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  adminPasswordUpdate,
  adminPasswordUpdateSuccess,
  employeePasswordUpdate,
  employeePasswordUpdateSuccess,
  logOutUser,
  login,
  loginFailure,
  loginSuccess,
  registerApplicant,
} from "./loginSlice";

function* onLoginUser({
  payload: { history, data, url = "/admin-auth/login", redirect = "/" },
}) {
  try {
    const response = yield call(post, url, data);
    // console.log(
    //   response?.user?.isLineManager,
    //   response?.user?.isAssessor,
    //   response?.user?.headOfTheDepartment
    // );
    // console.log(`onLoginUser ~ response`, response);
    const headOfTheDepartmentList = [
      "Head of Factory Operation",
      "Head of Procurement",
      "Head of Sales",
      "Head of Supply Chain",
      "Business Development Manager",
      "Chief Executive Officer",
      "Managing Director",
    ];

    const dashboardAllAccessList = [
      "Head of Human Resources",
      "Business Development Manager",
      "Chief Executive Officer",
      "Managing Director",
    ];

    let accessDashboard = null;
    let lineManagerOrSecondTire = null;

    if (response) {
      let role = "employee",
        roles = [];
      accessDashboard = {
        isHeadOfDepartment:
          headOfTheDepartmentList?.find(
            (el) => response?.user?.roleName === el
          ) ||
          response?.user?.isSecondTierLineManager === "Yes" ||
          response?.user?.isLineManager === "Yes"
            ? true
            : false,
        isAllAccess: dashboardAllAccessList?.find(
          (el) => response?.user?.roleName === el
        )
          ? true
          : false,
      };

      lineManagerOrSecondTire = {
        isLineManager:
          response?.user?.role === "ADMIN"
            ? null
            : headOfTheDepartmentList?.find(
                (el) => response?.user?.roleName === el
              ) ||
              dashboardAllAccessList?.find(
                (el) => response?.user?.roleName === el
              )
            ? null
            : response?.user?.isLineManager,
        isSecondTierLineManager:
          response?.user?.role === "ADMIN"
            ? null
            : headOfTheDepartmentList?.find(
                (el) => response?.user?.roleName === el
              ) ||
              dashboardAllAccessList?.find(
                (el) => response?.user?.roleName === el
              )
            ? null
            : response?.user?.isSecondTierLineManager,
      };

      if (response?.userType === "employee") {
        // if (response?.user?.headOfTheDepartment === "Yes") {
        //   // role = "headOfDepartment";
        //   roles.push("headOfDepartment");
        // }
        if (
          headOfTheDepartmentList?.find((el) => response?.user?.roleName === el)
        ) {
          // role = "headOfDepartment";
          roles.push("headOfDepartment");
        }

        if (response?.user?.isAssessor === "Yes") {
          // role = "assessor";
          roles.push("assessor");
        }
        if (response?.user?.isLineManager === "Yes") {
          // role = "lineManager";
          roles.push("lineManager");
        }
        if (
          response?.user?.headOfTheDepartment === "No" &&
          response?.user?.isAssessor === "No" &&
          response?.user?.isLineManager === "No"
        ) {
          roles.push("employee");
        }
      } else {
        role = response?.user?.role || response.userType;
      }

      yield put(
        loginSuccess({
          token: response?.token,
          role: role,
          roles,
          accessDashboard,
          lineManagerOrSecondTire,
          user: response?.user,
        })
      );
      axiosApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response?.token}`;
      toaster("success", "Login Successful");
      history.push(redirect);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Login Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
  }
}

function* onRegister({ payload: { history, data, setSubmitting } }) {
  try {
    const url = "/applicant-panel/profile/applicant-sign-up";
    const response = yield call(post, url, data);
    // console.log(`response`, response);
    if (response) {
      toaster("success", "Successful! Check your email");
      setSubmitting && setSubmitting(false);
      history.push(`/applicant/login`);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Registration Failed";
    toaster("error", message);
    yield put(loginFailure({ message }));
    setSubmitting && setSubmitting(false);
  }
}

function* onLogOutUser({ payload: { history, role } }) {
  if (role === "applicant") {
    yield history.push("/applicant/login");
  } else if (
    role === "employee" ||
    role === "assessor" ||
    role === "lineManager" ||
    role === "headOfDepartment"
  ) {
    yield history.push("/employee/login");
  } else {
    yield history.push("/login");
  }
}

function* onUpdateEmployeePassword({
  payload: { data, setSubmitting, toggle },
}) {
  try {
    const url = `/employee-auth/update-my-password`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      yield put(
        employeePasswordUpdateSuccess({
          token: response?.token,
          user: response?.user,
        })
      );

      toaster("success", "Change password successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Change password Failed";
    toaster("error", message);
    setSubmitting && setSubmitting(false);
  }
}

function* onUpdateAdminPassword({ payload: { data, setSubmitting, toggle } }) {
  try {
    const url = `/admin-auth/update-my-password`;
    const response = yield call(patch, url, data);
    // console.log(`response`, response);
    if (response) {
      yield put(
        adminPasswordUpdateSuccess({
          token: response?.token,
          user: response?.user,
        })
      );
      toaster("success", "Change password successful");
      setSubmitting && setSubmitting(false);
      toggle && toggle(true);
    }
  } catch (err) {
    const message = err?.response?.data?.message || "Change password Failed";
    toaster("error", message);
    setSubmitting && setSubmitting(false);
  }
}

// function* onUpdateProfile({ payload: { data, setSubmitting, id, toggle } }) {
//   try {
//     const url = `/applicant-panel/profile/${id}`;
//     const response = yield call(patch, url, data);
//     // console.log(`response`, response);
//     if (response) {
//       toaster("success", "Update profile successful");
//       setSubmitting && setSubmitting(false);
//       toggle && toggle(true);
//     }
//   } catch (err) {
//     const message = err?.response?.data?.message || "update profile Failed";
//     toaster("error", message);
//     yield put(loginFailure({ message }));
//     setSubmitting && setSubmitting(false);
//   }
// }

// function* onUpdateApplicantPassword({
//   payload: { data, setSubmitting, toggle },
// }) {
//   try {
//     const url = `/career-applicant-auth/updateMyPassword`;
//     const response = yield call(patch, url, data);
//     // console.log(`response`, response);
//     if (response) {
//       toaster("success", "Update profile successful");
//       setSubmitting && setSubmitting(false);
//       toggle && toggle(true);
//     }
//   } catch (err) {
//     const message = err?.response?.data?.message || "update profile Failed";
//     toaster("error", message);
//     yield put(loginFailure({ message }));
//     setSubmitting && setSubmitting(false);
//   }
// }

// eslint-disable-next-line import/prefer-default-export
export function* loginSaga() {
  yield takeEvery(login.type, onLoginUser);
  yield takeEvery(registerApplicant.type, onRegister);
  // yield takeEvery(updateProfile.type, onUpdateProfile);
  // yield takeEvery(updateApplicantPassword.type, onUpdateApplicantPassword);
  yield takeEvery(logOutUser.type, onLogOutUser);
  yield takeEvery(employeePasswordUpdate.type, onUpdateEmployeePassword);
  yield takeEvery(adminPasswordUpdate.type, onUpdateAdminPassword);
}
