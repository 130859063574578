import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addLocationData,
  deleteLocation,
  deleteLocationFailure,
  deleteLocationSuccess,
  getLocationList,
  getLocationListFailure,
  getLocationListSuccess,
  getSingleLocation,
  getSingleLocationFailure,
  getSingleLocationSuccess,
} from "./LocationSlice";

function* onGetLocation({ payload: { page = 0, limit = 0, name = "" } }) {
  try {
    const url = `/location/data?limit=${limit}&pageNo=${page}&name=${name}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getLocationListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get location failed";
    yield put(getLocationListFailure({ message }));
  }
}

function* onAddLocation({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/location/${id}` : `/location`;
    const response = yield call(id ? patch : post, url, data);

    if (response) {
      toaster("success", `Location ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Location ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleLocation({ payload: { id } }) {
  try {
    const url = `/location/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleLocationSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single location failed";
    toaster("error", message);
    yield put(getSingleLocationFailure({ message }));
  }
}

function* onDeleteLocation({ payload: { id, successCallBack } }) {
  try {
    const url = `/location/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteLocationSuccess(response));
      toaster("success", "Location deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Location deleted failed";
    toaster("error", message);
    yield put(deleteLocationFailure({ message }));
  }
}

export function* locationSaga() {
  yield takeEvery(getLocationList.type, onGetLocation);
  yield takeEvery(addLocationData.type, onAddLocation);
  yield takeEvery(getSingleLocation.type, onGetSingleLocation);
  yield takeEvery(deleteLocation.type, onDeleteLocation);
}
