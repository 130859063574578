import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  areaList: {},
  loading: false,
  error: "",
  singleArea: {},
  adding: false,
};

const areaSlice = createSlice({
  name: "area",
  initialState: initialState,
  reducers: {
    getAreaList: (state, action) => {
      state.loading = true;
    },
    getAreaListSuccess: (state, action) => {
      state.areaList = action.payload;
      state.loading = false;
    },
    getAreaListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAreaData: (state, action) => {},
    getSingleArea: (state, action) => {
      state.loading = true;
    },
    getSingleAreaSuccess: (state, action) => {
      state.singleArea = action.payload;
      state.loading = false;
    },
    getSingleAreaFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteArea: (state, action) => {
      state.adding = true;
    },
    deleteAreaSuccess: (state, action) => {
      state.adding = false;
    },
    deleteAreaFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getAreaList,
  getAreaListSuccess,
  getAreaListFailure,
  addAreaData,
  getSingleArea,
  getSingleAreaSuccess,
  getSingleAreaFailure,
  deleteArea,
  deleteAreaSuccess,
  deleteAreaFailure,
} = areaSlice.actions;

export default areaSlice.reducer;
