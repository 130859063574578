import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addAppraisalReportEmployeeData,
  getAppraisalReportEmployeeList,
  getAppraisalReportEmployeeListFailure,
  getAppraisalReportEmployeeListSuccess,
  getSingleAppraisalReportEmployee,
  getSingleAppraisalReportEmployeeFailure,
  getSingleAppraisalReportEmployeeSuccess,
} from "./AppraisalReportEmployeeSlice";

function* onAppraisalReportEmployee({
  payload: {
    performancePeriodId = "",
    appraisalStatus = "",
    lineManagerType = "",
    subordinateEmployeeId = "",
  },
}) {
  try {
    const url = `/employee-panel/performance-rating/appraisal-report?performancePeriodId=${performancePeriodId}&appraisalStatus=${appraisalStatus}&lineManagerType=${lineManagerType}&subordinateEmployeeId=${subordinateEmployeeId}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAppraisalReportEmployeeListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Appraisal Report Failed";
    yield put(getAppraisalReportEmployeeListFailure({ message }));
  }
}

function* onAddAppraisalReportEmployee({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/Own/${id}` : `/Own`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Appraisal report ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} appraisal report failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleAppraisalReportEmployee({ payload: { id } }) {
  try {
    const url = `/Own/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleAppraisalReportEmployeeSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single appraisal report failed";
    toaster("error", message);
    yield put(getSingleAppraisalReportEmployeeFailure({ message }));
  }
}

export function* appraisalReportEmployeeSaga() {
  yield takeEvery(
    getAppraisalReportEmployeeList.type,
    onAppraisalReportEmployee
  );
  yield takeEvery(
    addAppraisalReportEmployeeData.type,
    onAddAppraisalReportEmployee
  );
  yield takeEvery(
    getSingleAppraisalReportEmployee.type,
    onGetSingleAppraisalReportEmployee
  );
}
