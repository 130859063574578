import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addDesignationData,
  deleteDesignation,
  deleteDesignationFailure,
  deleteDesignationSuccess,
  getDesignationList,
  getDesignationListFailure,
  getDesignationListSuccess,
  getSingleDesignation,
  getSingleDesignationFailure,
  getSingleDesignationSuccess,
} from "./DesignationSlice";

function* onGetDesignation({ payload: { page = 1, limit } }) {
  try {
    const url = `/designation/data/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getDesignationListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get Designation Failed";
    yield put(getDesignationListFailure({ message }));
  }
}

function* onAddDesignation({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/designation/${id}` : `/designation`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Designation ${id ? "Updated" : "Added"} Successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      // yield put(getDesignationList({ page: 1, limit: 10 }));
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Updated" : "Added"} Designation Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleDesignation({ payload: { id } }) {
  try {
    const url = `/designation/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleDesignationSuccess(response?.designation));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single designation failed";
    toaster("error", message);
    yield put(getSingleDesignationFailure({ message }));
  }
}

function* onDeleteDesignation({ payload: { id, successCallBack } }) {
  try {
    const url = `/designation/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteDesignationSuccess(response));
      toaster("success", "Designation Deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete designation failed";
    toaster("error", message);
    yield put(deleteDesignationFailure({ message }));
  }
}

export function* designationSaga() {
  yield takeEvery(getDesignationList.type, onGetDesignation);
  yield takeEvery(addDesignationData.type, onAddDesignation);
  yield takeEvery(getSingleDesignation.type, onGetSingleDesignation);
  yield takeEvery(deleteDesignation.type, onDeleteDesignation);
}
