import { call, put, takeEvery } from "redux-saga/effects";
import { get } from "../../../../helpers/api_helper";
import {
  getKpiSetupReportList,
  getKpiSetupReportListFailure,
  getKpiSetupReportListSuccess,
} from "./KpiSetupReportSlice";

function* onKpiSetupReport({
  payload: {
    page = 0,
    limit = 0,
    performancePeriodId = "",
    department = "",
    designation = "",
    kpiSetupStatus = "",
  },
}) {
  try {
    const url = `/performance-rating/kpi-setup-report/?limit=${limit}&pageNo=${page}&performancePeriodId=${performancePeriodId}&department=${department}&designation=${designation}&kpiSetupStatus=${kpiSetupStatus}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getKpiSetupReportListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get KPI setup report failed";
    yield put(getKpiSetupReportListFailure({ message }));
  }
}

export function* kpiSetupReportSaga() {
  yield takeEvery(getKpiSetupReportList.type, onKpiSetupReport);
}
