import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamAppraisalList: {},
  loading: false,
  error: "",
};

const teamAppraisalSlice = createSlice({
  name: "teamAppraisal",
  initialState: initialState,
  reducers: {
    getTeamAppraisalList: (state, action) => {
      state.loading = true;
    },
    getTeamAppraisalListSuccess: (state, action) => {
      state.teamAppraisalList = action.payload;
      state.loading = false;
    },
    getTeamAppraisalListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addTeamAppraisalData: (state, action) => {},
    addTeamMemberAppraisalFeedback: (state, action) => {},
    addTeamMemberAppraisalFeedbackSecondTier: (state, action) => {},
    approvedSecondTierManager: (state, action) => {},
    addPromotionUpdateEmployee: (state, action) => {},
  },
});

export const {
  getTeamAppraisalList,
  getTeamAppraisalListSuccess,
  getTeamAppraisalListFailure,
  addTeamMemberAppraisalFeedbackSecondTier,
  addTeamAppraisalData,
  addTeamMemberAppraisalFeedback,
  approvedSecondTierManager,
  addPromotionUpdateEmployee,
} = teamAppraisalSlice.actions;

export default teamAppraisalSlice.reducer;
