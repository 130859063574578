import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assessmentList: {},
  assessmentAssignList: {},
  assessmentReviewData: {},
  loading: false,
  error: "",
  singleAssessment: {},
  adding: false,
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState: initialState,
  reducers: {
    storeAssessment: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getAssessmentList: (state, action) => {
      state.loading = true;
    },
    getAssessmentListSuccess: (state, action) => {
      state.assessmentList = action.payload;
      state.loading = false;
    },
    getAssessmentListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAssessmentData: (state, action) => {},
    getSingleAssessment: (state, action) => {
      state.loading = true;
    },
    getSingleAssessmentSuccess: (state, action) => {
      state.singleAssessment = action.payload;
      state.loading = false;
    },
    getSingleAssessmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteAssessment: (state, action) => {
      state.adding = true;
    },
    deleteAssessmentSuccess: (state, action) => {
      state.adding = false;
    },
    deleteAssessmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },

    getAssessmentAssignList: (state, action) => {
      state.loading = true;
    },
    getAssessmentAssignListSuccess: (state, action) => {
      state.assessmentAssignList = action.payload;
      state.loading = false;
    },
    getAssessmentAssignListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getAssessmentReview: (state, action) => {
      state.loading = true;
    },
    getAssessmentReviewSuccess: (state, action) => {
      state.assessmentReviewData = action.payload;
      state.loading = false;
    },
    getAssessmentReviewFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addAssessmentReview: (state, action) => {},
  },
});

export const {
  getAssessmentReview,
  getAssessmentReviewSuccess,
  getAssessmentReviewFailure,
  getAssessmentAssignList,
  getAssessmentAssignListSuccess,
  getAssessmentAssignListFailure,
  getAssessmentList,
  getAssessmentListSuccess,
  getAssessmentListFailure,
  addAssessmentData,
  addAssessmentReview,
  getSingleAssessment,
  getSingleAssessmentSuccess,
  getSingleAssessmentFailure,
  deleteAssessment,
  deleteAssessmentSuccess,
  deleteAssessmentFailure,
  getSearchUser,
  getSearchUserSuccess,
  getSearchUserFailure,
  storeAssessment,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
