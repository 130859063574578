import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  teamMemberKpiList: {},
  loading: false,
  error: "",
  singleTeamMemberKpi: {},
  adding: false,
};

const teamMemberKpiSlice = createSlice({
  name: "teamMemberKpi",
  initialState: initialState,
  reducers: {
    getTeamMemberKpiList: (state, action) => {
      state.loading = true;
    },
    getTeamMemberKpiListSuccess: (state, action) => {
      state.teamMemberKpiList = action.payload;
      state.loading = false;
    },
    getTeamMemberKpiListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addTeamMemberKpiData: (state, action) => {},
    addTeamMemberFeedbackKpi: (state, action) => {},
    getSingleTeamMemberKpi: (state, action) => {
      state.loading = true;
    },
    getSingleTeamMemberKpiSuccess: (state, action) => {
      state.singleTeamMemberKpi = action.payload;
      state.loading = false;
    },
    getSingleTeamMemberKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteTeamMemberKpi: (state, action) => {
      state.adding = true;
    },
    deleteTeamMemberKpiSuccess: (state, action) => {
      state.adding = false;
    },
    deleteTeamMemberKpiFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getTeamMemberKpiList,
  getTeamMemberKpiListSuccess,
  getTeamMemberKpiListFailure,
  addTeamMemberKpiData,
  getSingleTeamMemberKpi,
  getSingleTeamMemberKpiSuccess,
  getSingleTeamMemberKpiFailure,
  deleteTeamMemberKpi,
  deleteTeamMemberKpiSuccess,
  addTeamMemberFeedbackKpi,
  deleteTeamMemberKpiFailure,
} = teamMemberKpiSlice.actions;

export default teamMemberKpiSlice.reducer;
