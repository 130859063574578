import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addOwnKpiData,
  deleteOwnKpi,
  deleteOwnKpiFailure,
  deleteOwnKpiSuccess,
  getAllKpi,
  getAllKpiFailure,
  getAllKpiSuccess,
  getOwnKpiList,
  getOwnKpiListFailure,
  getOwnKpiListSuccess,
  getSingleOwnKpi,
  getSingleOwnKpiFailure,
  getSingleOwnKpiSuccess,
} from "./ownKpiSlice";

function* onOwnKpi({ payload: { year = "" } }) {
  try {
    const url = `/employee-panel/performance-rating/get-performace-period?year=${year}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getOwnKpiListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Open Kpi Setup Failed";
    yield put(getOwnKpiListFailure({ message }));
  }
}

function* onAddOwnKpi({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/performance-rating/setup-kpi/${id}`
      : `/employee-panel/performance-rating/setup-kpi`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Own KPI ${id ? "updated" : "added"} successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} own KPI failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleOwnKpi({ payload: { id } }) {
  try {
    const url = `/employee-panel/performance-rating/get-performance-period-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleOwnKpiSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single own KPI failed";
    toaster("error", message);
    yield put(getSingleOwnKpiFailure({ message }));
  }
}

function* onGetAllKpi({ payload: { page = 0, limit = 0 } }) {
  try {
    const url = `/employee-panel/performance-rating/all-kpi-list/?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAllKpiSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get all KPI failed";
    toaster("error", message);
    yield put(getAllKpiFailure({ message }));
  }
}

function* onDeleteOwnKpi({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-panel/performance-rating/${id}`; // TODO:
    const response = yield call(del, url);
    if (response) {
      yield put(deleteOwnKpiSuccess(response));
      toaster("success", "Open own KPI deleted successfully");
      successCallBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Delete own KPI failed";
    toaster("error", message);
    yield put(deleteOwnKpiFailure({ message }));
  }
}

export function* ownKpiSaga() {
  yield takeEvery(getOwnKpiList.type, onOwnKpi);
  yield takeEvery(addOwnKpiData.type, onAddOwnKpi);
  yield takeEvery(getSingleOwnKpi.type, onGetSingleOwnKpi);
  yield takeEvery(getAllKpi.type, onGetAllKpi);
  yield takeEvery(deleteOwnKpi.type, onDeleteOwnKpi);
}
