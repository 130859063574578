import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departmentList: {},
  loading: false,
  error: "",
  singleDepartment: {},
  adding: false,
  storeDepartmentList: [],
};

const departmentSlice = createSlice({
  name: "department",
  initialState: initialState,
  reducers: {
    resetDevelopment: (state, action) => {
      state.storeDepartmentList = [];
    },
    storeDepartmentSelection: (state, { payload: { name, data } }) => {
      state[name] = data;
    },
    getDepartmentList: (state, action) => {
      state.loading = true;
    },
    getDepartmentListSuccess: (state, action) => {
      state.departmentList = action.payload;
      state.loading = false;
    },
    getDepartmentListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addDepartmentData: (state, action) => {},
    getSingleDepartment: (state, action) => {
      state.loading = true;
    },
    getSingleDepartmentSuccess: (state, action) => {
      state.singleDepartment = action.payload;
      state.loading = false;
    },
    getSingleDepartmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteDepartment: (state, action) => {
      state.adding = true;
    },
    deleteDepartmentSuccess: (state, action) => {
      state.adding = false;
    },
    deleteDepartmentFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  resetDevelopment,
  storeDepartmentSelection,
  getDepartmentList,
  getDepartmentListSuccess,
  getDepartmentListFailure,
  addDepartmentData,
  getSingleDepartment,
  getSingleDepartmentSuccess,
  getSingleDepartmentFailure,
  deleteDepartment,
  deleteDepartmentSuccess,
  deleteDepartmentFailure,
} = departmentSlice.actions;

export default departmentSlice.reducer;
