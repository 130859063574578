import { call, put, takeEvery } from "redux-saga/effects";
import { get, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addInterviewResultReviewData,
  addOfferLetterNegotiateData,
  getAdminOfferLetter,
  getAdminOfferLetterFailure,
  getAdminOfferLetterSuccess,
  getIntervieweeList,
  getIntervieweeListFailure,
  getIntervieweeListSuccess,
  getOfferLetterList,
  getOfferLetterListFailure,
  getOfferLetterListSuccess,
  getSingleInterviewResult,
  getSingleInterviewResultFailure,
  getSingleInterviewResultSuccess,
  sendEmail,
  sendEmailFail,
  sendEmailSuccess,
} from "./IntervieweeSlice";

function* onGetInterviewee({
  payload: { page = 1, limit, interviewStatus = "", jobPost = "" },
}) {
  try {
    const url = `/career/interviewees-list/?limit=${limit}&pageNo=${page}&interviewStatus=${interviewStatus}&jobPostId=${jobPost}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getIntervieweeListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Get interviewee Failed";
    yield put(getIntervieweeListFailure({ message }));
  }
}

function* onGetSingleInterviewResult({ payload: { id = "" } }) {
  try {
    // console.log(`id`, id);
    const url = `/career/get-intereview-result-by-applicant/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleInterviewResultSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single interviewee result Failed";
    yield put(getSingleInterviewResultFailure({ message }));
  }
}
function* onAddInterviewResultReviewData({
  payload: {
    data,
    options: { setSubmitting, resetForm, history },
  },
}) {
  try {
    const url = `/career/intereview-result-review`;
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `Interview Result Review Submitted Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push("/admin/interviewee-list");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Add Interview Result Review Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetOfferLetterData({
  payload: { page = 1, limit, status = "", jobPost = "", negotiable = "" },
}) {
  try {
    const url = `/career/offer-letter-list/?limit=${limit}&pageNo=${page}&jobPostId=${jobPost}&status=${status}&negotiateStatus=${negotiable}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getOfferLetterListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Offer Letter  Data Failed";
    yield put(getOfferLetterListFailure({ message }));
  }
}
function* onGetAdminOfferLetterData({ payload: { id = "" } }) {
  try {
    const url = `/career/show-offer-letter/${id}`;
    const response = yield call(get, url);
    // console.log("pppp", response);
    if (response) {
      yield put(getAdminOfferLetterSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Admin Offer Letter  Data Failed";
    yield put(getAdminOfferLetterFailure({ message }));
  }
}

function* OnAddOfferLetterNegotiateData({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle },
  },
}) {
  try {
    const url = `/career/offer-letter-negotiate`;
    const response = yield call(post, url, data);
    if (response) {
      toaster("success", `Submitted Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Add Offer Letter Negotiate Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onSendEmail({ payload: { data = {}, callBack = () => {} } }) {
  const { id, name, email, jobTitle, result } = data;
  try {
    const url = `/career/interview-result-mail-send-to-interviewe?id=${id}&fullName=${name}&email=${email}&jobTitle=${jobTitle}&result=${result}`;
    const response = yield call(get, url);
    if (response) {
      yield put(sendEmailSuccess(response));
      toaster("success", "Mail send successfully");
      callBack && callBack();
    }
  } catch (error) {
    const message = error?.response?.data?.message || "Mail send Failed";
    yield put(sendEmailFail({ message }));
  }
}

export function* adminIntervieweeSaga() {
  yield takeEvery(getIntervieweeList.type, onGetInterviewee);
  yield takeEvery(
    addOfferLetterNegotiateData.type,
    OnAddOfferLetterNegotiateData
  );
  yield takeEvery(getSingleInterviewResult.type, onGetSingleInterviewResult);
  yield takeEvery(getOfferLetterList.type, onGetOfferLetterData);
  yield takeEvery(getAdminOfferLetter.type, onGetAdminOfferLetterData);
  yield takeEvery(
    addInterviewResultReviewData.type,
    onAddInterviewResultReviewData
  );
  yield takeEvery(sendEmail.type, onSendEmail);
}
