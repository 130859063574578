import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addOwnAppraisalData,
  getOwnAppraisalList,
  getOwnAppraisalListFailure,
  getOwnAppraisalListSuccess,
} from "./OwnAppraisalSlice";

function* onOwnAppraisal({
  payload: { performancePeriodId = "", appraisalStatus = "" },
}) {
  try {
    const url = `/employee-panel/performance-rating/get-own-appraisal-list?performancePeriodId=${performancePeriodId}&appraisalStatus=${appraisalStatus}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getOwnAppraisalListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Own Appraisal Failed";
    yield put(getOwnAppraisalListFailure({ message }));
  }
}

function* onAddOwnAppraisal({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id
      ? `/employee-panel/performance-rating/own-appraisal-submit/${id}`
      : `/employee-panel/performance-rating/own-appraisal-submit/`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster(
        "success",
        `Own appraisal ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} own appraisal failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* ownAppraisalSaga() {
  yield takeEvery(getOwnAppraisalList.type, onOwnAppraisal);
  yield takeEvery(addOwnAppraisalData.type, onAddOwnAppraisal);
}
