import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  regionList: {},
  loading: false,
  error: "",
  singleRegion: {},
  adding: false,
};

const regionSlice = createSlice({
  name: "region",
  initialState: initialState,
  reducers: {
    getRegionList: (state, action) => {
      state.loading = true;
    },
    getRegionListSuccess: (state, action) => {
      state.regionList = action.payload;
      state.loading = false;
    },
    getRegionListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addRegionData: (state, action) => {},
    getSingleRegion: (state, action) => {
      state.loading = true;
    },
    getSingleRegionSuccess: (state, action) => {
      state.singleRegion = action.payload;
      state.loading = false;
    },
    getSingleRegionFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteRegion: (state, action) => {
      state.adding = true;
    },
    deleteRegionSuccess: (state, action) => {
      state.adding = false;
    },
    deleteRegionFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getRegionList,
  getRegionListSuccess,
  getRegionListFailure,
  addRegionData,
  getSingleRegion,
  getSingleRegionSuccess,
  getSingleRegionFailure,
  deleteRegion,
  deleteRegionSuccess,
  deleteRegionFailure,
} = regionSlice.actions;

export default regionSlice.reducer;
