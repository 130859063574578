import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  kpiSetupReportList: {},
  loading: false,
  error: "",
};

const kpiSetupReportSlice = createSlice({
  name: "kpiSetupReport",
  initialState: initialState,
  reducers: {
    resetKpiReportData: (state, action) => {
      state.kpiSetupReportList = {};
      state.loading = false;
      state.error = "";
    },
    getKpiSetupReportList: (state, action) => {
      state.loading = true;
    },
    getKpiSetupReportListSuccess: (state, action) => {
      state.kpiSetupReportList = action.payload;
      state.loading = false;
    },
    getKpiSetupReportListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
  },
});

export const {
  resetKpiReportData,
  getKpiSetupReportList,
  getKpiSetupReportListSuccess,
  getKpiSetupReportListFailure,
} = kpiSetupReportSlice.actions;

export default kpiSetupReportSlice.reducer;
