import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationList: {},
  loading: false,
  error: "",
  singleLocation: {},
  adding: false,
};

const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    getLocationList: (state, action) => {
      state.loading = true;
    },
    getLocationListSuccess: (state, action) => {
      state.locationList = action.payload;
      state.loading = false;
    },
    getLocationListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addLocationData: (state, action) => {},
    getSingleLocation: (state, action) => {
      state.loading = true;
    },
    getSingleLocationSuccess: (state, action) => {
      state.singleLocation = action.payload;
      state.loading = false;
    },
    getSingleLocationFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteLocation: (state, action) => {
      state.adding = true;
    },
    deleteLocationSuccess: (state, action) => {
      state.adding = false;
    },
    deleteLocationFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getLocationList,
  getLocationListSuccess,
  getLocationListFailure,
  addLocationData,
  getSingleLocation,
  getSingleLocationSuccess,
  getSingleLocationFailure,
  deleteLocation,
  deleteLocationSuccess,
  deleteLocationFailure,
} = locationSlice.actions;

export default locationSlice.reducer;
