import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../../../helpers/api_helper";
import { toaster } from "../../../../helpers/custom/Toast";
import {
  addEmployeeCategoryData,
  deleteEmployeeCategory,
  deleteEmployeeCategoryFailure,
  deleteEmployeeCategorySuccess,
  getEmployeeCategoryList,
  getEmployeeCategoryListFailure,
  getEmployeeCategoryListSuccess,
  getSingleEmployeeCategory,
  getSingleEmployeeCategoryFailure,
  getSingleEmployeeCategorySuccess,
} from "./EmployeeCategorySlice";

function* onGetEmployeeCategory({ payload: { page = 1, limit } }) {
  try {
    const url = `/employee-category/data?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getEmployeeCategoryListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get employee category failed";
    yield put(getEmployeeCategoryListFailure({ message }));
  }
}

function* onAddEmployeeCategory({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/employee-category/${id}` : `/employee-category`;
    const response = yield call(id ? patch : post, url, data);
    // console.log(response);
    if (response) {
      toaster(
        "success",
        `Employee category ${id ? "updated" : "added"} successfully`
      );
      yield setSubmitting(false);
      yield resetForm();
      toggle(true);
    }
  } catch (error) {
    // console.log(error);
    const message =
      error?.response?.data?.message ||
      `Employee category ${id ? "update" : "add"} failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

function* onGetSingleEmployeeCategory({ payload: { id } }) {
  try {
    const url = `/employee-category/${id}`;
    const response = yield call(get, url);

    if (response) {
      yield put(getSingleEmployeeCategorySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single employee category failed";
    toaster("error", message);
    yield put(getSingleEmployeeCategoryFailure({ message }));
  }
}

function* onDeleteEmployeeCategory({ payload: { id, successCallBack } }) {
  try {
    const url = `/employee-category/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteEmployeeCategorySuccess(response));
      toaster("success", "Employee category deleted successfully");
      successCallBack(true);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Employee category deleted failed";
    toaster("error", message);
    yield put(deleteEmployeeCategoryFailure({ message }));
  }
}

export function* employeeCategorySaga() {
  yield takeEvery(getEmployeeCategoryList.type, onGetEmployeeCategory);
  yield takeEvery(addEmployeeCategoryData.type, onAddEmployeeCategory);
  yield takeEvery(getSingleEmployeeCategory.type, onGetSingleEmployeeCategory);
  yield takeEvery(deleteEmployeeCategory.type, onDeleteEmployeeCategory);
}
