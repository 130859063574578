import { call, put, takeEvery } from "redux-saga/effects";
import { get, patch } from "../../../helpers/api_helper";
import { toaster } from "../../../helpers/custom/Toast";
import {
  addPromotionUpdate,
  getAppraisalReportList,
  getAppraisalReportListFailure,
  getAppraisalReportListSuccess,
  getSingleAppraisalReport,
  getSingleAppraisalReportFailure,
  getSingleAppraisalReportSuccess,
} from "./AppraisalReportSlice";

function* onAppraisalReport({
  payload: {
    page = 0,
    limit = 0,
    performancePeriodId = "",
    department = "",
    designation = "",
    unit = "",
    appraisalStatus = "",
  },
}) {
  try {
    const url = `/performance-rating/appraisal-report/?limit=${limit}&pageNo=${page}&performancePeriodId=${performancePeriodId}&department=${department}&designation=${designation}&unit=${unit}&appraisalStatus=${appraisalStatus}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAppraisalReportListSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get Appraisal Report Failed";
    yield put(getAppraisalReportListFailure({ message }));
  }
}

function* onGetSingleAppraisalReport({ payload: { id } }) {
  try {
    const url = `/Own/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleAppraisalReportSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single appraisal report failed";
    toaster("error", message);
    yield put(getSingleAppraisalReportFailure({ message }));
  }
}

function* onAddPromotionUpdate({
  payload: {
    data,
    options: { toggle, id, setSubmitting, resetForm },
  },
}) {
  try {
    const url = `/performance-rating/promotion-recommendation-update/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      toaster("success", `Promotion recommendation updated`);
      toggle(true);
    }
    yield setSubmitting(true);
    yield resetForm(true);
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `Promotion recommendation updated failed`;
    toaster("error", message);
    yield setSubmitting(true);
  }
}

export function* appraisalReportSaga() {
  yield takeEvery(getAppraisalReportList.type, onAppraisalReport);
  yield takeEvery(getSingleAppraisalReport.type, onGetSingleAppraisalReport);
  yield takeEvery(addPromotionUpdate.type, onAddPromotionUpdate);
}
