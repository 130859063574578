import { call, put, takeEvery } from "redux-saga/effects";
import { del, get, patch, post } from "../../helpers/api_helper";
import { toaster } from "../../helpers/custom/Toast";
import {
  addAssessmentData,
  addAssessmentReview,
  deleteAssessment,
  deleteAssessmentFailure,
  deleteAssessmentSuccess,
  getAssessmentAssignList,
  getAssessmentAssignListFailure,
  getAssessmentAssignListSuccess,
  getAssessmentList,
  getAssessmentListFailure,
  getAssessmentListSuccess,
  getAssessmentReview,
  getAssessmentReviewFailure,
  getAssessmentReviewSuccess,
  getSingleAssessment,
  getSingleAssessmentFailure,
  getSingleAssessmentSuccess,
} from "./AssessmentSlice.js";

function* onGetAssessment({
  payload: { page = 1, limit, type = "", status = "", department = "" },
}) {
  try {
    const url = `/assessment/data?limit=${limit}&pageNo=${page}${
      type ? "&type=" + type : ""
    }${status ? "&status=" + status : ""}${
      department ? `&department=${department}` : ""
    }`;
    const response = yield call(get, url);
    yield put(getAssessmentListSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || "Get assessment failed";
    toaster("error", message);
    yield put(getAssessmentListFailure({ message }));
  }
}

function* onAddAssessment({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id ? `/assessment/${id}` : `/assessment`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster("success", `Assessment ${id ? "updated" : "added"} successfully`);
      setSubmitting(false);
      resetForm();
      history.push("/admin/assessment-list");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? "Update" : "Add"} assessment Failed`;
    toaster("error", message);
    setSubmitting(false);
  }
}

function* onGetSingleAssessment({ payload: { id } }) {
  try {
    const url = `/assessment/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleAssessmentSuccess(response?.assessment));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get single assessment failed";
    toaster("error", message);
    yield put(getSingleAssessmentFailure({ message }));
  }
}

function* onDeleteAssessment({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/assessment/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteAssessmentSuccess(response));
      toaster("success", "Assessment delete successfully");
      // setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getAssessmentList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Delete assessment failed";
    toaster("error", message);
    yield put(deleteAssessmentFailure({ message }));
  }
}

function* onGetAssessmentAssign({
  payload: { page = 1, limit, jobPostId = "", status = "" },
}) {
  try {
    const url = `/career/get-assessment-assigned-list?limit=${limit}&pageNo=${page}&status=${status}&jobPostId=${jobPostId}`;
    const response = yield call(get, url);
    // console.log("responseee", response);
    yield put(getAssessmentAssignListSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get assessment assign failed";
    toaster("error", message);
    yield put(getAssessmentAssignListFailure({ message }));
  }
}

function* onGetAssessmentReview({ payload: { id } }) {
  try {
    const url = `/career/get-assessment-assigned-by-id/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getAssessmentReviewSuccess(response?.assessment));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || "Get assessment review failed";
    toaster("error", message);
    yield put(getAssessmentReviewFailure({ message }));
  }
}

function* onAddAssessmentReview({
  payload: {
    data,
    options: { setSubmitting, resetForm, id, assessmentId, history },
  },
}) {
  try {
    const url = `/career/assessment-review-and-remarks/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      yield put(getAssessmentReview({ id: assessmentId }));
      toaster("success", `Assessment Review Data Added Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push("/admin/assessment-assign-list");
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `Assessment review added Failed`;
    toaster("error", message);
    yield setSubmitting(false);
  }
}

export function* assessmentSaga() {
  yield takeEvery(getAssessmentList.type, onGetAssessment);
  yield takeEvery(addAssessmentData.type, onAddAssessment);
  yield takeEvery(getSingleAssessment.type, onGetSingleAssessment);
  yield takeEvery(deleteAssessment.type, onDeleteAssessment);
  yield takeEvery(getAssessmentAssignList.type, onGetAssessmentAssign);
  yield takeEvery(getAssessmentReview.type, onGetAssessmentReview);
  yield takeEvery(addAssessmentReview.type, onAddAssessmentReview);
}
