import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  employeeCategoryList: {},
  loading: false,
  error: "",
  singleEmployeeCategory: {},
  adding: false,
};

const employeeCategorySlice = createSlice({
  name: "employeeCategory",
  initialState: initialState,
  reducers: {
    getEmployeeCategoryList: (state, action) => {
      state.loading = true;
    },
    getEmployeeCategoryListSuccess: (state, action) => {
      state.employeeCategoryList = action.payload;
      state.loading = false;
    },
    getEmployeeCategoryListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addEmployeeCategoryData: (state, action) => {},
    getSingleEmployeeCategory: (state, action) => {
      state.loading = true;
    },
    getSingleEmployeeCategorySuccess: (state, action) => {
      state.singleEmployeeCategory = action.payload;
      state.loading = false;
    },
    getSingleEmployeeCategoryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteEmployeeCategory: (state, action) => {
      state.adding = true;
    },
    deleteEmployeeCategorySuccess: (state, action) => {
      state.adding = false;
    },
    deleteEmployeeCategoryFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getEmployeeCategoryList,
  getEmployeeCategoryListSuccess,
  getEmployeeCategoryListFailure,
  addEmployeeCategoryData,
  getSingleEmployeeCategory,
  getSingleEmployeeCategorySuccess,
  getSingleEmployeeCategoryFailure,
  deleteEmployeeCategory,
  deleteEmployeeCategorySuccess,
  deleteEmployeeCategoryFailure,
} = employeeCategorySlice.actions;

export default employeeCategorySlice.reducer;
